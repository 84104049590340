import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import Tippy from '@tippyjs/react'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    feeTypes
} from '../../../util/util-constants'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {CreditCardIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {
    checkPerm,
    classNames,
    getAmountText,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    openInNewTab,
    resourceIsCreated,
    saveTableColumns,
    updateQuery
} from '../../../common/util/util-helpers'
import CarrierPayDialog from './carrier-pay-dialog'
import moment from 'moment'
import {uploadDocument} from '../../../data/actions/download'
import PayDateFilter from './carrier-pay-date-filter'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import {toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";

export default function CarrierPayrollView({translate, match, history, location}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    const isLoading = resource.isLoading
    const pagePath = location.pathname.substring(1)
    const data = resource?.data?.list ?? []
    const count = getProp(resource, 'data.count', 0)

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useState(getQueryFields(LocalStorage.get('carrier-pay_filters') ?? LocalStorage.get('carrier_pay_filters')))
    const query = FieldsManager.getFieldKeyValues(queryFields)
    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, translate))
    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false)
    const [isCreateBillDialogOpen, setIsCreateBillDialogOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState({})
    const [selectedItems, setSelectedItems] = useState([])
    const [isBulkBill, setIsBulkBill] = useState(false)
    const [files, setFiles] = useState({})
    const [breakpoint, setBreakpoint] = useState({})
    const hasSelectedRows = !!Object.values(selectedRows).length
    /** Constants
     ================================================================= */
    const totalAmount = genericMoneyFormatter(Object.values(selectedRows).reduce((memo, it) => memo + it.TotalAmount, 0))

    /** UI Events
     ================================================================= */
    const handleQueryInputChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, ['query', 'CarrierID', 'LoadID', 'StartDate', 'EndDate', 'DateType'], name, value)

        if (name !== 'offset') {
            queryUpdate.offset.value = "0"
        }

        if (!queryUpdate.StartDate.props) {
            queryUpdate.StartDate.props = {}
        }

        if (!queryUpdate.EndDate.props) {
            queryUpdate.EndDate.props = {}
        }

        queryUpdate.StartDate.props.maxDate = queryUpdate.EndDate.value
        queryUpdate.EndDate.props.minDate = queryUpdate.StartDate.value

        if ('ALL_FIELDS' === name) {
            queryUpdate.DateType.value = 2
            setQueryFields(queryUpdate)
        } else {
            setQueryFields(FieldsManager.updateField(queryUpdate, name, value))
        }

        saveFilters(queryUpdate)
    }

    const handleCreateBillClick = (it = []) => {
        setSelectedItems([it])
        setIsCreateBillDialogOpen(true)
        setFiles({})
        setIsBulkBill(false)
    }

    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    const setTableColumnOptions = (columns) => {
        let tableOptionsUpdate = cloneDeep(tableOptions)

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsUpdate)
        setIsColumnsDialogVisible(false)
        saveTableColumns(pagePath, tableOptionsUpdate)
    }

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(getFields(), {}, translate)
    }

    const toggleBillBulkItems = () => {
        setIsCreateBillDialogOpen(!isCreateBillDialogOpen)
        setSelectedItems(Object.values(selectedRows))
        setFiles({})
        setIsBulkBill(!isBulkBill)
    }

    const handleSelectRowClick = (item) => {
        const itemID = item.CarrierPayID

        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleSelectAllClick = (selectAll) => {
        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (!selectAll) {
            Object.assign(selectedRowsUpdate, data.reduce((memo, it) => {
                memo[it.CarrierPayID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRowsUpdate)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.CarrierPayID.toString())) {
                    delete selectedRowsUpdate[it.CarrierPayID]
                }
            })
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleRemoveFile = (key) => {
        let filesUpdate = Object.assign({}, files)
        delete filesUpdate[key]
        setFiles(filesUpdate)
    }

    const handleAcceptFiles = (file, CarrierPayID) => {
        setFiles(existingValues => ({
            ...existingValues,
            [CarrierPayID]: file,
        }))
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: query
        }))
    }

    const saveFilters = (queryUpdate) => {
        LocalStorage.set('carrier-pay_filters', Object.assign(FieldsManager.getFieldKeyValues(queryUpdate),
            {
                Carrier: queryUpdate.CarrierID?.value?.label,
                Load: queryUpdate.LoadID?.value?.value
            })
        )
    }

    const submitDocuments = (ids = []) => {
        // Update file params so that every file is sent to a different ID.
        const filesToUpload = Object.values(files).reduce((memo, it, index) => {
            if (it[0]) {
                it[0].params = {
                    id: ids[index]
                }
            }
            memo.push(it[0])
            return memo
        }, [])

        if (filesToUpload.length) {
            dispatch(uploadDocument({
                user: LocalStorage.get('user'),
                files: filesToUpload,
                Descriptions: filesToUpload.map(() => ''),
                resource: Resources.ExpenseDocuments,
            }))
        }
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
        if (breakpoint.index <= 1 && queryFields.limit.value !== 10) {
            handleQueryInputChange('limit', 10)
        }
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [queryFields])

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setIsCreateBillDialogOpen(false)
            setSelectedRows({})
            submitDocuments(resource.create.ids)
        }
    }, [resource])

    /** Body
     ================================================================= */
    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            onBreakpointChange={handleBreakpointChange}
            isAccessible={!(resource.errorStatus === 2)}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.carrier_payroll')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <div className="sm:flex z-[32]">
                    <ActiveFilters
                        filterFields={queryFields}
                        excludedFields={['sort', 'sortBy', 'offset', 'limit', 'DateType']}
                        onLabelClick={handleQueryInputChange}
                        onClearFiltersClick={() => handleQueryInputChange('ALL_FIELDS')}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <TableSettingsPopOver
                            options={tableOptions}
                            setOptions={setOptions}
                            toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                            translate={translate}
                        />
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={excludeFields(cloneDeep(queryFields), ['sort', 'sortBy', 'offset', 'StartDate', 'EndDate', 'DateType'])}
                        handleInputChange={handleQueryInputChange}
                        onRefreshTable={fetchData}
                        selects={{
                            CarrierID: {
                                api: 'api/' + Resources.CarriersQuick,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.CarrierID,
                                    label: item.LegalName,
                                    metaData: item
                                })
                            }, LoadID: {
                                api: 'api/' + Resources.LoadsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.LoadID,
                                    label: item.LoadNumber
                                })
                            }, IsHoldCarrierPay: {
                                "0": "No",
                                "1": "Yes",
                                "": "All"
                            }
                        }}
                        customHtml={
                            <PayDateFilter
                                queryFields={queryFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    setQueryFields(queryFieldsUpdate)
                                    saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={handleQueryInputChange}
                            />
                        }
                        translate={translate}
                        isLoading={resource.isLoading}
                    />

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        canCreate={checkPerm(getResourceName(), CREATE_PERM)}
                        title={translate('text.no_matching_records')}
                        text={translate('text.create_new_resource', [queryFields.query.value])}
                        onClearFilterClick={() => handleQueryInputChange('ALL_FIELDS')}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={queryFields}
                        excludeFields={['limit', 'sort', 'sortBy', 'offset']}
                    />

                    <ResourceTable
                        verticalTableIsVisible={breakpoint.index <= 1}

                        data={data}
                        fields={getFields(translate)}

                        translate={translate}
                        isLoading={isLoading}

                        options={tableOptions}

                        limit={queryFields.limit.value}

                        sort={queryFields.sort.value}
                        sortBy={queryFields.sortBy.value}
                        onSortChange={(fieldName) => handleQueryInputChange('sortBy', fieldName)}

                        onSelectRow={handleSelectRowClick}
                        selectedRows={selectedRows}
                        onSelectAllClick={handleSelectAllClick}
                        tableKey={'CarrierPayID'}

                        actions={[
                            {
                                action: handleCreateBillClick,
                                icon: CreditCardIcon, // make this a function
                                visible: (item) => !item?.IsPayed,
                                title: translate('text.create_bill'),
                            },
                        ]}
                    />

                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value) => handleQueryInputChange(name, value)
                            }
                            queryFields={queryFields}
                            translate={translate}
                        />

                        <TableBulkActions
                            selectedRows={selectedRows}
                            tableKey={'CarrierPayID'}

                            fields={getFields(translate)}
                            translate={translate}
                            options={tableOptions}
                            setSelectedRows={setSelectedRows}
                            onSelectAllClick={handleSelectAllClick}
                            footerContent={
                                hasSelectedRows && (
                                    <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                        <div className="whitespace-nowrap">
                                            Total amount for selected entries:
                                            <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                        </div>
                                    </div>)
                            }
                        >
                            <div className='flex items-center h-8'>
                                <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                    <div className='flex gap-x-1 pr-2'>
                                        <Tippy
                                            content={translate('btn.bulk_create_bill')}>
                                            <button
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                                disabled={!hasSelectedRows}
                                                onClick={toggleBillBulkItems}
                                            >
                                                <CreditCardIcon
                                                    className={'text-primary-contrast w-5 h-5'}
                                                />
                                            </button>
                                        </Tippy>
                                    </div>
                                </div>
                            </div>
                        </TableBulkActions>
                    </TableCardFooter>
                </TableCard>
            </Page>

            <TableOptionsDialog
                show={isColumnsDialogVisible}
                pagePath={pagePath}
                columns={tableOptions.columns}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />

            <ModalDefault
                show={isCreateBillDialogOpen}
                title={translate('text.create_bill')}
                widthClass={'max-w-3xl w-screen'}
                buttonLabel={translate('btn.edit')}
                onButtonClick={() => null}
                onClose={() => setIsCreateBillDialogOpen(false)}
                closeButtonLabel={translate('btn.close')}
                hideDialogFooter={true}
                translate={translate}
            >
                <CarrierPayDialog
                    selectedItems={selectedItems}
                    onClose={() => setIsCreateBillDialogOpen(false)}
                    query={query}
                    files={Object.assign({}, files)}
                    onAcceptFiles={handleAcceptFiles}
                    onRemoveFile={handleRemoveFile}
                    translate={translate}
                />
            </ModalDefault>
        </Layout>
    )
}

const getFields = (translate) => {
    return {
        LoadID: new Field('LoadID', '', [''], false, 'search-select', {
            render: (item) => {
                if (!item) return null

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            openInNewTab(`/loads/info/${item.LoadID}`)
                        }}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.LoadNumber}
                    </button>
                )
            }
        }),
        CarrierID: new Field('CarrierID', '', [''], false, 'select-search', {
            render: (item) => {
                if (!item) return null

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            openInNewTab(`/carriers/info/${item.CarrierOrganizationID}/${item.CarrierID}`)
                        }}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.Carrier}
                    </button>
                )
            }
        }),
        IsHoldCarrierPay: new Field('IsHoldCarrierPay', '', [''], false, 'custom', {
            render: (item) => {
                if (!item) return null

                return (
                    <div className="text-center">
                        {
                            item.IsHoldCarrierPay
                                ? <Tippy
                                    content={`Pay put on hold by ${item.HoldCarrierPay} at ${toFrontDateTimeFromUTC(item.HoldCarrierPayDateTime)}`}
                                    disabled={!item.HoldCarrierPay || !item.HoldCarrierPayDateTime}
                                >
                                    <ExclamationTriangleIcon className={'inline text-red-600 w-5 h-5'}/>
                                </Tippy>
                                : <XMarkIcon className='inline w-5 h-5 text-tm-gray-400'/>
                        }
                    </div>
                )
            }
        }),
        CarrierPayNotes: new Field('CarrierPayNotes', '', [''], false, 'textarea', {limitWidth: true, omitSort: true}),
        CustomerID: new Field('CustomerID', '', [''], false, 'select-search', {
            render: (item) => {
                if (!item) return null
                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            openInNewTab(`/customers/info/${item.CustomerOrganizationID}/${item.CustomerID}`)
                        }}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.Customer}
                    </button>
                )
            }
        }),
        FeeTypeID: new Field('FeeTypeID', '', [''], false, 'custom', {
            render: (item) => {
                return (
                    <>{translate(feeTypes[item.FeeTypeID])}</>
                )
            }
        }),
        Amount: new Field('Amount', '', [''], false, 'text', {
            render: (it) => getAmountText(it, translate)
        }),
        TotalAmount: new Field('TotalAmount', '', [''], false, 'money'),

        PickupDate: new Field('PickupDate', '', [''], false, 'date'),
        Pickup: new Field('Pickup', '', [''], false, 'text'),
        PickupCityName: new Field('PickupCityName', '', [''], false, 'text'),
        PickupState: new Field('PickupState', '', [''], false, 'text'),
        PickupPostalCode: new Field('PickupPostalCode', '', [''], false, 'text'),

        DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
        Destination: new Field('Destination', '', [''], false, 'text'),
        DestinationCityName: new Field('DestinationCityName', '', [''], false, 'text'),
        DestinationState: new Field('DestinationState', '', [''], false, 'text'),
        DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, 'text'),

        IsTonu: new Field('IsTonu', '', ['empty'], false, 'checkbox'),
        IsPODReady: new Field('IsPODReady', '', ['empty'], false, 'custom', {
            render: (it) => {
                return (
                    it.IsPODReady
                        ?
                        <>
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>

                            ({it.DocumentsReadyCount ?? 0} of {it.DocumentsRequiredCount ?? 0})
                        </>
                        :
                        <>
                            <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                            ({it.DocumentsReadyCount ?? 0} of {it.DocumentsRequiredCount ?? 0})
                        </>

                )
            }
        }),
        IsPODReviewed: new Field('IsPODReviewed', '', [], false, 'checkbox'),
        IsAccessorialHold: new Field('IsAccessorialHold', '', ['empty'], false, 'custom', {
            altIcon: <ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>,
            render: (it) => {
                if (it.IsAccessorialHold === 1) {
                    return (
                        <div className="flex justify-center">
                            <CheckCircleIcon className="w-5 h-5 text-yellow-600"/>
                        </div>)
                } else {
                    return (
                        <div className="flex justify-center">
                            <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                        </div>
                    )

                }
            }
        }),
    }
}

const getQueryFields = (data = {}) => {
    const fieldTemplates = {
        query: new Field('query', '', [''], false, 'search'),
        CarrierID: new Field('CarrierID', '', [''], false, 'select-search', {}, {
            isClearable: true
        }),
        LoadID: new Field('LoadID', '', [''], false, 'select-search', {}, {isClearable: true}),
        IsHoldCarrierPay: new Field('IsHoldCarrierPay', '', [''], false, 'select', {}, {isClearable: true}),
        StartDate: new Field('StartDate', moment().startOf('month').format(DEFAULT_DATABASE_DATETIME_FORMAT), [], false, 'date'),
        EndDate: new Field('EndDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [], false, 'date'),
        DateType: new Field('DateType', 2, [''], false, 'button-group', {
            data: {1: 'Pickup', 2: 'Delivery'},
            addClass: 'w-full',
            addButtonClass: 'uppercase font-semibold',
            addContainerClass: 'col-span-2',
            labelType: 'stack',
        }),
        sort: new Field('sort', 'ASC', [''], false, 'text'),
        sortBy: new Field('sortBy', 'CarrierPayID', [''], false, 'text'),

        offset: new Field('offset', '0', [''], false, 'text'),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
            labelType: 'float',
            hideLabel: true
        }, {menuPlacement: 'top'})
    }

    return fillFieldsFromData(fieldTemplates, data)
}

const getResourceName = () => Resources.CarrierPayroll

const getTableOptions = (pagePath, translate) => {
    return getDefaultTableOptions(getFields(translate), {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true
        },
        columns: {
            LoadNumber: {
                minWidth: 150,
            },
            IsHoldCarrierPay: {minWidth: 80},
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        }
    }, pagePath, translate)
}

