import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    onDocumentPrint,
    resourceIsUpdated,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDate, toFrontDateTime} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {
    ArrowPathIcon,
    ArrowSmallDownIcon,
    ArrowSmallLeftIcon,
    ArrowSmallRightIcon,
    ArrowSmallUpIcon,
    CheckCircleIcon,
    MagnifyingGlassIcon,
    PrinterIcon
} from '@heroicons/react/24/outline'
import Env from '../../../util/env'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import moment from 'moment'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import FileViewer from "../../../common/components/file-viewer/components"
import ModalDefault from "../../../common/components/modal/modal-default";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ResourceTable from "../../../common/components/resource-table";
import InfoParagraph from "../../../common/components/info-paragraph";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Badge from "../../../common/components/badge";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Tooltip from "../../../common/components/tooltip";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import {getJWT} from "../../../common/util/util-auth";
import {READ_PERM} from "../../../common/util/util-consts";

const EXPENSE_CLAIM_STATUS = {
    1: 'Ready for Print',
    2: 'Printed',
    3: 'Cleared',
    4: 'Voided',
    5: 'Not Cleared'
}

class CheckTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_checks'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Date',
            sort: 'DESC',

            // Fields
            fields: this.getFields(),
            offsetFields: this.getOffsetFields(),
            printFields: this.getPrintFields(),
            lastGoodNumberField: this.getLastGoodNumberField(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            printChequeParams: {},
            isOffsetStateDirty: false,
            isTableFullScreen: false,

            isPrintCheckModalOpen: false,
            isPrintPreviewModalOpen: false,
            isBulkPrintPreviewDialogOpen: false,
            isLastGoodNumberModalOpen: false,

            isPreviewVisible: false,
            previewParams: {},
            isConfirmCheckPrintVisible: false,
            isPDFOffsetFormVisible: false,
            previewKey: 1,
            confirmModalOpen: false,
            confirmVoidModalOpen: false,
            selectedItem: null,

            selectedRows: {},
            isPreviewPrintedDocumentModalOpen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.intervalRef = React.createRef()

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (
            resourceIsUpdated(this.props.resource, prevProps.resource)
            && this.props.resource.resource === Resources.ExpenseChecks
        ) {
            this.handleClosePrintDialogClick()
        }

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (prevProps.resource.data?.AutoCounter != this.props.resource.data?.AutoCounter) {
            this.setState({
                printFields: this.getPrintFields()
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'expenses_checks_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    handlePartialPrintConfirmation = () => {
        const initialCheckNumber = this.state.printFields.AutoCounter.value
        const lastNumberPrinted = parseInt(this.state.lastGoodNumberField.LastGoodNumber.value) || initialCheckNumber + Object.keys(this.state.selectedRows).length
        const numOfChecksToPrint = lastNumberPrinted - initialCheckNumber + 1

        let printedChecks = this.getSortedDataForSelectedRows()
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                AutoCounter: this.state.printFields.AutoCounter.value.toString(),
                SendPaymentCheckIDs: printedChecks.map(it => it.SendPaymentCheckID).slice(0, numOfChecksToPrint)
            },
            errorMessage: true,
            successMessage: this.props.translate('text.successful_print_confirmed'),
            query: this.getQuery(),
            resource: Resources.ExpenseChecksPrint,
            piggyResource: this.getResource()
        }))

        this.handleClosePrintDialogClick()
        this.setState({
            selectedRows: {},
            isBulkPrintPreviewDialogOpen: false,
            isLastGoodNumberModalOpen: false
        })
    }

    handleConfirmPrintClick = (params) => {
        const isBulkPrint = this.state.isBulkPrintPreviewDialogOpen
        let printUpdateParams

        if (isBulkPrint) {
            let printedChecks = this.getSortedDataForSelectedRows()
            printUpdateParams = {
                AutoCounter: this.state.printFields.AutoCounter.value.toString(),
                SendPaymentCheckIDs: printedChecks.map(it => it.SendPaymentCheckID)
            }
        } else {
            printUpdateParams = {
                SendPaymentCheckID: params.SendPaymentCheckID,
                AutoCounter: params.AutoCounter
            }
        }

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: printUpdateParams,
            errorMessage: true,
            successMessage: this.props.translate('text.successful_print_confirmed'),
            query: this.getQuery(),
            resource: Resources.ExpenseChecksPrint,
            piggyResource: this.getResource()
        }))

        this.handleClosePrintDialogClick()
        this.setState({
            selectedRows: {},
            isBulkPrintPreviewDialogOpen: false,
        })
    }

    submitOffset = () => {
        const {OffsetDown, OffsetRight} = FieldsManager.getFieldKeyValues(this.state.offsetFields)
        const params = Object.assign(
            this.state.previewParams,
            {
                OffsetX: OffsetRight,
                OffsetY: OffsetDown,
            }
        )

        this.setState({
            previewParams: params,
            previewKey: this.state.previewKey + 1,
            isOffsetStateDirty: false
        })
    }

    downloadDocument = () => {
        this.props.dispatch(download({
            resource: Resources.ExpenseChecksPrint,
            query: {
                name: 'check.pdf',
                SendPaymentCheckID: this.state?.selectedItem?.SendPaymentCheckID,
                Remit: 1,
                token: getJWT().access_token
            }
        }))
    }

    /** UI Events
     ================================================================= */
    handleOpenPrintDialogClick = (item = {}) => {
        this.setState({
            selectedItem: item,
            isPrintCheckModalOpen: true
        })
    }

    handleToggleBulkPrintPreview = () => {
        this.setState({isBulkPrintPreviewDialogOpen: true})
    }

    handleClosePrintDialogClick = () => {
        this.setState({
            isPrintCheckModalOpen: false,
            isPreviewVisible: false,
            isConfirmCheckPrintVisible: false,
            isPDFOffsetFormVisible: false,
            confirmModalOpen: false
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'Date'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value)

        if (name === 'ChargeTo') {
            if (value == 0) {
                fieldsUpdate.PayToContactID.validate = ['empty']
                fieldsUpdate.PayToContactID.type = 'select-search'
                fieldsUpdate.PayToID.value = ''
                fieldsUpdate.PayToID.type = 'hidden'
                fieldsUpdate.PayToID.validate = ['']
            } else {
                fieldsUpdate.PayToID.validate = ['empty']
                fieldsUpdate.PayToID.type = 'select-search'
                fieldsUpdate.PayToContactID.value = ''
                fieldsUpdate.PayToContactID.type = 'hidden'
                fieldsUpdate.PayToContactID.validate = ['']
            }
        }

        return fieldsUpdate
    }

    handleAutoCounterInputChange = (name, value) => {
        this.setState({
            printFields: FieldsManager.updateField(this.state.printFields, name, value)
        })
    }

    handleLastGoodNumberChange = (name, value) => {
        this.setState({
            lastGoodNumberField: FieldsManager.updateField(this.state.lastGoodNumberField, name, value)
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleToggleConfirmModal = (submit = () => {
    }) => {
        this.setState({
            confirmModalAction: submit,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleCloseVoidConfirmModal = () => {
        this.setState({
            confirmVoidModalOpen: false, voidID: null
        })
    }

    handleSetDefaultTableColumnOptions = () => {
        const tableOptions = getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)

        this.setState({
            tableOptions: tableOptions,
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handlePreviewPrintClick = () => {
        const params = this.state.printChequeParams

        if (!!params && !!Object.keys(params).length) {
            if (!this.state.isPreviewVisible) {
                this.setState({
                    isPreviewVisible: true,
                    previewParams: params,
                    isOffsetStateDirty: false
                })
            } else {
                let params = this.state.previewParams
                delete params['PayFor']
                onDocumentPrint(Env.getApiUrl('api/' + Resources.ExpenseChecksPrint, Object.assign({}, {
                    ...params,
                    name: 'cheque_' + this.state.previewParams?.SendPaymentCheckID + '.pdf'
                })), 'pdf')

                this.setState({
                    isConfirmCheckPrintVisible: true
                })
            }
        }
    }

    handleOpenVoidConfirmDialog = (item = {}) => {
        this.setState({
            selectedItem: item,
            confirmVoidModalOpen: true
        })
    }

    handleSubmitVoid = (item) => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {SendPaymentCheckID: item.SendPaymentCheckID},
            resource: this.getResource(),
            piggyResource: this.getResource(),
            piggyQuery: this.getQuery(),
            errorMessage: true, successMessage: 'Check voided.'
        }))

        this.setState({selectedItem: null, confirmVoidModalOpen: false})
    }

    handleOffsetInputChange = (name) => {
        let value = (+this.state.offsetFields[name].value) + 1
        let fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, name, value)
        if (name === 'OffsetDown') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetUp', -value)
        }
        if (name === 'OffsetUp') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetDown', -value)
        }
        if (name === 'OffsetRight') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetLeft', -value)
        }
        if (name === 'OffsetLeft') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetRight', -value)
        }

        fieldsUpdate[name].errorMessage = ''

        this.setState({
            offsetFields: fieldsUpdate,
            isOffsetStateDirty: true
        })
    }

    handleOffsetButtonHoldStart = (name) => {
        if (this.intervalRef.current) return
        this.intervalRef.current = setInterval(() => {
            this.handleOffsetInputChange(name)
        }, 50)
    }

    handleOffsetButtonHoldStop = () => {
        if (this.intervalRef.current) {
            clearInterval(this.intervalRef.current)
            this.intervalRef.current = null
        }
    }

    handleOffsetSubmitClick = () => {
        let areOffsetsValid = true
        let fields = Object.values(this.state.offsetFields).map(it => {
            if (isNaN(it.value)) {
                it.errorMessage = ['error.value_isNaN']
                areOffsetsValid = false
            }
            return it
        })

        fields = fields.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        if (!areOffsetsValid) {
            this.setState({
                offsetFields: fields,
                isOffsetStateDirty: false
            })
        } else {
            this.submitOffset()
        }
    }

    handleSelectRowClick = (item) => {
        const itemID = item.SendPaymentCheckID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            selectedRows[itemID] = data.find(it => it.SendPaymentCheckID === itemID)
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (areAllSelected) => {
        let selectedRows = cloneDeep(this.state.selectedRows)

        getProp(this.props.resource.data, 'list', []).map(item => {
            if (!areAllSelected) {
                if (item.CheckStatusID == 1) {
                    selectedRows[item.SendPaymentCheckID] = item
                }
            } else {
                delete selectedRows[item.SendPaymentCheckID]
            }
        })

        this.setState({
            selectedRows
        })
    }

    handleBulkPrintChecksPreview = () => {
        const params = {
            AutoCounter: this.state.printFields.AutoCounter.value,
            SendPaymentCheckIDs: this.getSortedDataForSelectedRows().map(it => it.SendPaymentCheckID),
        }

        this.setState({
            printChequeParams: params,
            isPrintCheckModalOpen: true
        }, () => {
            this.handlePreviewPrintClick()
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    handleShowPrintedDocument = (it) => {
        this.setState({
            selectedItem: it,
            isPreviewPrintedDocumentModalOpen: true
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getStatusBadge = (item) => {
        switch (item.CheckStatusID) {
            case 4:
                return (
                    <div className="grid justify-center">
                        <Badge type="info"
                               className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                            {EXPENSE_CLAIM_STATUS[item.CheckStatusID]}
                        </Badge>

                        <div className="text-tm-gray-600 mt-px leading-3">
                            {(item?.VoidDate ? ' ' + toFrontDate(item.VoidDate) : '')}
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="grid justify-center">
                        <Badge type="success"
                               className="block flex-grow-0 text-center px-3 py-0.5 rounded-btn text-sm font-medium">
                            {EXPENSE_CLAIM_STATUS[item.CheckStatusID]}
                        </Badge>
                    </div>
                )
            default:
                return (
                    <div className="grid justify-center">
                        <Badge type="info"
                               className="block flex-grow-0 text-center px-3 py-0.5 rounded-btn text-sm font-medium"
                        > {EXPENSE_CLAIM_STATUS[item.CheckStatusID]}</Badge>
                    </div>
                )
        }
    }

    getPrintFields = (item = {}) => {
        const autoCounterValue = getProp(this.props.resource, 'data.AutoCounter', 0) + 1

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', autoCounterValue, ['empty'], false, 'integer', {
                addContainerClass: 'col-span-3',
                label: 'cheque_number'
            }),
            SendPaymentCheckID: new Field('SendPaymentCheckID', '', [], false, 'hidden'),
            PayFor: new Field('PayFor', '', [], true, 'text', {addContainerClass: 'col-span-full'}),
            ChargeTo: new Field('ChargeTo', item?.PayToID ? 1 : 0, [''], false, 'button-group', {
                data: {
                    0: 'Contact',
                    1: 'Organization'
                },
                addClass: 'w-full',
                addContainerClass: 'col-span-full'
            }),
            PayToID: new Field('PayToID', '', item?.PayToID ? ['empty'] : [''], false, item?.PayToID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                label: 'OrganizationID'
            }),
            PayToContactID: new Field('PayToContactID', '', item?.PayToContactID ? ['empty'] : [''], false, item?.PayToContactID ? 'select-search' : 'hidden', {addContainerClass: 'col-span-full'}),
            Description: new Field('Description', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full'
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQuery = () => {
        let {
            CheckStatusID,
            PayToID,
            PayToContactID,
            AccountID,
            EndDate,
            StartDate,
            NotReconciled,
            limit,
            query
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        let IsReconciled = ''
        switch (parseInt(CheckStatusID)) {
            case 3: {
                CheckStatusID = 2
                IsReconciled = 1
                break
            }
            case 5: {
                CheckStatusID = 2
                NotReconciled = 1
                break
            }
            default: {
                // pass
            }
        }

        return {
            query,
            ...(NotReconciled && {NotReconciled: 1}),
            ...(IsReconciled && {IsReconciled: 1}),
            limit,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            searchFields: JSON.stringify({
                CheckStatusID: CheckStatusID,
                PayToID: PayToID,
                PayToContactID: PayToContactID,
                AccountID: AccountID,
                ...(EndDate && {EndDate: ['Date', '<=', EndDate]}),
                ...(StartDate && {StartDate: ['Date', '>=', StartDate]})
            })
        }
    }

    getFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={'flex justify-end'}>
                                    <CheckCircleIcon className="w-5 h-5 text-green-600 mr-4"/>
                                    <div>{item.AutoCounter}</div>
                                </div>
                            </Tippy>
                        )
                    }
                    return <div>{item.AutoCounter}</div>
                }
            }),
            CheckStatusID: new Field('CheckStatusID', '', [], false, 'custom', {
                addTableHeaderClass: 'mx-auto',
                render: (it) => this.getStatusBadge(it)
            }),
            AccountID: new Field('AccountID', '', [], false, 'select-search'),
            PayFor: new Field('PayFor', '', [], false, 'text'),
            PayToID: new Field('PayToID', '', [], false, 'custom', {
                render: (item) => this.renderPayToIDCell(item)
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => (
                    <div className={'text-right'}>{genericMoneyFormatter(item.Amount)}</div>
                )
            }),
            Date: new Field('Date', '', [], false, 'date'),
            Description: new Field('Description', '', [], false, 'custom', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            PayToID: new Field('PayToID', '', [''], false, 'select-search', {label: 'PayToOrganization'}, {
                isClearable: true,
            }),
            CheckStatusID: new Field('CheckStatusID', '', [], false, 'select', {}, {
                isClearable: true,
            }),
            PayToContactID: new Field('PayToContactID', '', [''], false, 'select-search', {label: 'PayToContact'}, {isClearable: true}),
            AccountID: new Field('AccountID', '', [''], false, 'select-search', {}, {isClearable: true}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'float'}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'float'}),
            NotReconciled: new Field('NotReconciled', '', [], false, 'checkbox', {labelType: 'float'}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
        }
    }

    getOffsetFields = () => {
        return {
            OffsetDown: new Field('OffsetDown', 0, [''], false, 'integer'),
            OffsetUp: new Field('OffsetUp', 0, [''], false, 'integer'),
            OffsetLeft: new Field('OffsetLeft', 0, [''], false, 'integer'),
            OffsetRight: new Field('OffsetRight', 0, [''], false, 'integer'),
        }
    }

    getLastGoodNumberField = () => {
        return {
            LastGoodNumber: new Field('LastGoodNumber', 0, [''], false, 'integer')
        }
    }

    getResource = () => {
        return Resources.ExpenseChecks
    }

    getSecondaryButtonLabel = () => {
        if (this.state.isPreviewVisible && !this.state.isBulkPrintPreviewDialogOpen) {
            return this.props.translate('btn.back')
        } else if (this.state.isPreviewVisible && this.state.isBulkPrintPreviewDialogOpen && this.state.isConfirmCheckPrintVisible) {
            return this.props.translate('btn.confirm_checks_partial')
        } else {
            return undefined
        }
    }

    handleSecondaryClick = () => {
        if (this.state.isPreviewVisible && !this.state.isBulkPrintPreviewDialogOpen) {
            this.setState({
                isPreviewVisible: false,
                isConfirmCheckPrintVisible: false
            })
        } else if (this.state.isPreviewVisible && this.state.isBulkPrintPreviewDialogOpen && this.state.isConfirmCheckPrintVisible) {
            this.setState({
                isLastGoodNumberModalOpen: true
            })
        }
    }

    getSortedDataForSelectedRows = () => {
        return Object.values(cloneDeep(this.state.selectedRows)).sort((a, b) => {
            return moment(a.Date).isBefore(moment(b.Date)) ? -1 : 1
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Render
     ================================================================= */
    renderPayToIDCell = (item) => {
        const isPayToOrganization = !!item.PayToID
        const isLocationInfoValid = !!item.StateID && !!item.CityName && !!item.PostalCode

        return (
            <div className="flex">
                <CellButton
                    onClick={() => item.PayToID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                    {item.PayTo ?? item.PayToContact}
                </CellButton>
                {isPayToOrganization && !isLocationInfoValid && (
                    <Tippy
                        content={this.props.translate('text.location_info_missing')}
                    >
                        <div>
                            <ExclamationTriangleIcon className={'ml-2 w-5 h-5 text-red-600'}/>
                        </div>
                    </Tippy>
                )}
            </div>
        )
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))

        const isCheckNumberValid = this.state.printFields.AutoCounter.value >= getProp(this.props.resource, 'data.AutoCounter', 0) + 1
        const lastGoodNumberFieldHtml = fieldsToHtml(Object.values(this.state.lastGoodNumberField), translate, this.handleLastGoodNumberChange)

        const isLastGoodNumberValid = (this.state.lastGoodNumberField.LastGoodNumber.value >= this.state.printFields.AutoCounter.value) && (this.state.lastGoodNumberField.LastGoodNumber.value < parseInt(this.state.printFields.AutoCounter.value) + Object.keys(this.state.selectedRows).length)

        const previewBulkData = this.getSortedDataForSelectedRows().map((it, i) => {
            it.AutoCounter = parseInt(this.state?.printFields?.AutoCounter?.value) + i
            return it
        })
        const {
            OffsetDown,
            OffsetUp,
            OffsetLeft,
            OffsetRight
        } = FieldsManager.getFieldKeyValues(this.state.offsetFields)

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                    <div className="ml-auto flex">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}


                        selects={{
                            PayToID: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: {},
                                searchMap: (item) => ({
                                    label: item.LegalName,
                                    value: item.OrganizationID
                                })
                            },
                            PayToContactID: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: {},
                                searchMap: (item) => ({
                                    label: item.FirstName + ' ' + item.LastName,
                                    value: item.ContactID
                                })
                            },
                            AccountID: {
                                api: 'api/' + Resources.AccountsQuick,
                                query: {
                                    AccountTypeID: ACCOUNT_TYPE_CASH,
                                },
                                searchMap: (item) => ({
                                    label: item.AccountNumber + ' ' + item.AccountName,
                                    value: item.AccountID
                                })
                            },
                            CheckStatusID: EXPENSE_CLAIM_STATUS
                        }}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        commonTable={true}

                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleOpenPrintDialogClick,
                                icon: PrinterIcon,
                                visible: (item) => item.CheckStatusID == 1,
                                hasPerm: checkPerm(Resources.ExpenseChecksPrint, UPDATE_PERM),
                                title: translate('text.print'),
                            },
                            {
                                action: this.handleShowPrintedDocument,
                                icon: MagnifyingGlassIcon,
                                hasPerm: checkPerm(Resources.ExpenseChecksPrint, READ_PERM),
                                title: translate('text.document_preview'),
                                order: 1
                            },
                            {
                                action: this.handleOpenVoidConfirmDialog,
                                icon: XMarkIcon,
                                visible: (item) => item.CheckStatusID == 2 && !item.ReconciliationDate,
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('text.void'),
                            }
                        ]}

                        isSelectRowDisabled={(item) => item.CheckStatusID != 1}
                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey={'SendPaymentCheckID'}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey="SendPaymentCheckID"
                        fields={this.getFields()}
                        translate={translate}
                        options={this.state.tableOptions}
                        setSelectedRows={this.setSelectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                </div>
                            </div>
                        }
                    >

                        {checkPerm(Resources.ExpenseChecksPrint, UPDATE_PERM) && (
                            <Tippy content={translate('btn.bulk_print')}>
                                <button
                                    onClick={this.handleToggleBulkPrintPreview}
                                    disabled={!hasSelectedRows}
                                    className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                >
                                    <PrinterIcon
                                        className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                </button>
                            </Tippy>
                        )}
                    </TableBulkActions>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <ModalDefault
                    show={this.state.isLastGoodNumberModalOpen}
                    widthClass={'max-w-xl'}
                    title={'Last good number'}
                    buttonLabel={'Confirm'}
                    onButtonClick={this.handlePartialPrintConfirmation}
                    buttonDisabled={!isLastGoodNumberValid}
                    closeButtonLabel={translate('btn.cancel')}
                    onClose={() => this.setState({
                        isLastGoodNumberModalOpen: false
                    })}
                >
                    <div className={'my-4'}>
                        <div className={'text-lg mx-4 mb-4'}>Please enter a number of the last check that was correctly
                            printed.
                        </div>
                        <div className={'mx-12 px-12'}>
                            {lastGoodNumberFieldHtml}
                        </div>
                    </div>

                </ModalDefault>

                <ModalDefault
                    show={this.state.isBulkPrintPreviewDialogOpen}
                    widthClass={'max-w-full'}
                    title={translate('text.preview_checks_print')}
                    buttonLabel={translate('btn.confirm')}
                    onButtonClick={this.handleBulkPrintChecksPreview}
                    buttonDisabled={!isCheckNumberValid}
                    closeButtonLabel={translate('btn.Close')}

                    customHtmlLeft={fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.printFields, ['AutoCounter']))), translate, this.handleAutoCounterInputChange)}

                    onClose={() => this.setState({
                        isBulkPrintPreviewDialogOpen: false
                    })}
                >
                    <InfoParagraph
                        className="m-2"
                        message={'Checks will be printed by the date in the ascending order. Note that recommended number of checks per batch is up to 50.'}
                    />

                    <ResourceTable
                        data={previewBulkData}
                        limit="500"
                        maxHeightClass="max-h-[calc(100vh-16rem)]"
                        isLoading={isLoading}
                        fields={
                            {
                                AutoCounter: new Field('AutoCounter', '', [], false, 'text'),
                                AccountID: new Field('AccountID', '', [], false, 'select-search'),
                                PayFor: new Field('PayFor', '', [], false, 'text'),
                                PayToID: new Field('PayToID', '', [], false, 'custom', {
                                    render: (it) => {
                                        return it.PayTo ?? it.PayToContact
                                    }
                                }),
                                Amount: new Field('Amount', '', [], false, 'custom', {
                                    addTableHeaderClass: 'ml-auto',
                                    render: (item) => (
                                        <div className={'text-right'}>{genericMoneyFormatter(item.Amount)}</div>
                                    )
                                }),
                                Date: new Field('Date', '', [], false, 'date'),
                                Description: new Field('Description', '', [], false, 'custom', {
                                    render: (item) => (
                                        <div>{longTableColumn(item.Description)}</div>
                                    )
                                })
                            }
                        }
                        options={{
                            behaviour: {
                                hasVirtualRows: true
                            },
                            style: {
                                verticalLines: true,
                                horizontalLines: true
                            },
                        }}
                        translate={translate}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={translate('text.print_cheque')}
                    widthClass={this.state.isPreviewVisible ? 'max-w-7xl' : 'max-w-md'}
                    show={this.state.isPrintCheckModalOpen}
                    gridClass={this.state.isPreviewVisible ? 'grid gap-4 p-6 col-span-6 h-[calc(100vh-12rem)]' : undefined}
                    canSubmit={true}
                    onClose={this.handleClosePrintDialogClick}
                    buttonLabel={
                        this.state.isConfirmCheckPrintVisible
                            ? translate('btn.confirm_cheque')
                            : this.state.isPreviewVisible ? translate('btn.print_cheque') : translate('btn.print_preview')
                    }
                    onSecondaryButtonClick={this.handleSecondaryClick}
                    secondaryButtonLabel={this.getSecondaryButtonLabel()}
                    fields={this.getPrintFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (this.state.isConfirmCheckPrintVisible) {
                            this.handleToggleConfirmModal(() => this.handleConfirmPrintClick(params))
                        } else {
                            this.setState({
                                printChequeParams: params
                            }, () => {
                                this.handlePreviewPrintClick()
                            })
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        PayToID: {
                            api: 'api/' + Resources.OrganizationsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.OrganizationID,
                                label: item.LegalName
                            })
                        },
                        PayToContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + ' ' + item.LastName,
                                Contact: item
                            })
                        }
                    }}
                    htmlAfter={
                        <React.Fragment>
                            {this.state.isPreviewVisible && (
                                <div className="absolute left-0 right-0 top-16 bottom-16 bg-popup z-1 overflow-auto">
                                    {this.state.isPDFOffsetFormVisible && (
                                        <div
                                            className="bg-tm-gray-50 border-b border-tm-gray-300 px-6 pt-1.5 pb-3 flex gap-x-4">
                                            <div>
                                                <div className="grid grid-cols-5 gap-1">
                                                    {/* ROW 1 */}
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>
                                                    <div
                                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>
                                                        {+OffsetUp}
                                                    </div>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>

                                                    {/* ROW 2 */}
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                                        <button
                                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900 focus:outline-none"
                                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetUp')}
                                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                                        >
                                                            <ArrowSmallUpIcon className={'w-5 h-5'}/>
                                                        </button>
                                                    </div>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>

                                                    {/* ROW 3 */}
                                                    <div
                                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetLeft}</div>
                                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                                        <button
                                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetLeft')}
                                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                                        >
                                                            <ArrowSmallLeftIcon className={'w-5 h-5'}/>
                                                        </button>
                                                    </div>
                                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                                        <button
                                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                                            onClick={() => this.setState({offsetFields: this.getOffsetFields()})}
                                                        >
                                                            <ArrowPathIcon className={'w-5 h-5'}/>
                                                        </button>
                                                    </div>
                                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                                        <button
                                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetRight')}
                                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                                        >
                                                            <ArrowSmallRightIcon className={'w-5 h-5'}/>
                                                        </button>
                                                    </div>
                                                    <div
                                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetRight}</div>

                                                    {/* ROW 4 */}
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                                        <button
                                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetDown')}
                                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                                        >
                                                            <ArrowSmallDownIcon className={'w-5 h-5'}/>
                                                        </button>
                                                    </div>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>

                                                    {/* ROW 5 */}
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>
                                                    <div
                                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetDown}</div>
                                                    <div className={'w-5 h-5'}/>
                                                    <div className={'w-5 h-5'}/>
                                                </div>
                                            </div>

                                            <div className="space-x-4 mt-5 ml-auto">
                                                {!(this.state.isConfirmCheckPrintVisible && !this.state.isOffsetStateDirty) && (
                                                    <Tooltip
                                                        content="Offset is unchanged"
                                                    >
                                                        <div>
                                                            <button
                                                                className="btn btn-outline h-10"
                                                                onClick={() => {
                                                                    this.handleOffsetSubmitClick()
                                                                }}
                                                            >
                                                                {translate('btn.preview_offset_changes')}
                                                            </button>
                                                        </div>
                                                    </Tooltip>
                                                )}

                                                {this.state.isConfirmCheckPrintVisible && !this.state.isOffsetStateDirty && (
                                                    <button
                                                        className="btn btn-outline h-10"
                                                        onClick={() => {
                                                            this.handlePreviewPrintClick()
                                                        }}
                                                    >
                                                        {translate('btn.print_again')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <FileViewer
                                        key={this.state.previewKey}
                                        onPDFLoad={() => {
                                            this.setState({
                                                isPDFOffsetFormVisible: true
                                            })
                                        }}
                                        fileType={'pdf'}
                                        filePath={Env.getApiUrl('api/' + Resources.ExpenseChecksPrint, Object.assign(this.state.previewParams, {
                                            PayFor: '',
                                            token: getJWT().access_token
                                        }))}
                                        onError={(e) => {
                                            console.log(e)
                                        }}
                                    />
                                </div>
                            )}

                            <ModalConfirm
                                title={'Confirm'}
                                show={!!this.state.confirmModalOpen}
                                text={'Are you sure check is printed ok?'}
                                onClose={() => this.handleToggleConfirmModal()}
                                buttonLabel={translate('btn.confirm')}
                                closeButtonLabel={'Cancel'}
                                translate={translate}
                                onConfirm={this.state.confirmModalAction}
                            />
                        </React.Fragment>
                    }
                />

                <ModalConfirm
                    title={'Confirm'}
                    widthClass={'max-w-2xl'}
                    show={!!this.state.confirmVoidModalOpen}
                    text={translate('text.voidConfirm', [this.state.selectedItem?.AutoCounter])}
                    onClose={() => {
                        this.handleCloseVoidConfirmModal()
                    }}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.handleSubmitVoid(this.state.selectedItem)
                    }}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.handleSetDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.PayToID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.PayToContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                <ModalDefault
                    show={!!this.state.isPreviewPrintedDocumentModalOpen}
                    widthClass={'max-w-7xl'}
                    title={'Document preview'}
                    limitHeight={true}
                    onClose={() => {
                        this.setState({isPreviewPrintedDocumentModalOpen: false})
                    }}
                    closeButtonLabel={translate('Close')}
                    buttonLabel={translate('btn.download')}
                    onButtonClick={this.downloadDocument}
                >
                    <FileViewer
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.ExpenseChecksPrint, Object.assign({}, {
                            SendPaymentCheckID: this.state?.selectedItem?.SendPaymentCheckID,
                            Remit: 1,
                            token: getJWT().access_token
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(CheckTab)


