import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../util/util-constants'
import moment from 'moment'
import {
    timeFromUTCToFront,
    toBackDateTime,
    toFrontDateFromUTC,
    toFrontDateTimeFromUTC
} from '../../../common/util/util-dates'
import {Link} from 'react-router-dom'
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import Card from "../../../common/components/card";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import InfoParagraph from "../../../common/components/info-paragraph";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";

class IntegratedMoneyCodesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props?.location?.pathname?.substring(1) + 'integrated-money-codes' ?? ''

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: '',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            createModalOpen: false,
            confirmDialog: false,
            isImportModalOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleToggleImportModal = (item = null) => {
        this.setState({isImportModalOpen: !this.state.isImportModalOpen, selectedItem: item})
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        let queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        if (name === 'StartDate') {
            queryFields.EndDate.props = {
                minDate: queryFields.StartDate.value,
                maxDate: toBackDateTime(moment(queryFields.StartDate.value).add(6, 'd'))
            }

            if (moment(value).diff(queryFields.EndDate.value,'days') < -6) {
                queryFields.EndDate.value = toBackDateTime(moment(value).add(6, 'd'))
            }
        }

        if (name === 'EndDate') {
            queryFields.StartDate.props = {
                maxDate: queryFields.EndDate.value,
                minDate: toBackDateTime(moment(queryFields.EndDate.value).subtract(6, 'd'))
            }

            if (moment(value).diff(queryFields.StartDate.value,'days') > 6) {
                queryFields.StartDate.value = toBackDateTime(moment(value).subtract(6, 'd'))
            }

        }

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            // this.saveFilters();

            if (name !== 'query') {
                this.fetchData();
            }
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            // this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = Object.assign({}, this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        let queryFilterFields = Object.assign({}, this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            // this.saveFilters()
            this.fetchData()
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    /*
    code: "2234768457"
    issuedTo: "Bozeman, TBozeman, Tevin"
    amount: 212.25
    amountUsed: 212.25
    feeAmount: 2.5
    created: "2023-05-12T07:41:00.000-05:00"
    firstUse: "2023-05-12 07:42"
    notes: "Lumper fee in amount of 212.2"
    status: "ACTIVE"
    voided: false
     */
    getFields = () => {

        return {
            // Table
            code: new Field('code', '', [], false, 'text', {hideDialog: true}),
            issuedTo: new Field('issuedTo', '', [], false, 'text', {hideDialog: true}),
            amount: new Field('amount', '', [], false, 'float', {hideDialog: true}),
            amountUsed: new Field('amountUsed', '', [], false, 'float', {hideDialog: true}),
            feeAmount: new Field('feeAmount', '', [], false, 'float', {hideDialog: true}),
            status: new Field('status', '', [], false, 'float', {hideDialog: true}),
            notes: new Field('notes', '', [], false, 'text', {hideDialog: true}),
            created: new Field('created', '', [], false, 'datetimezone', {
                addTableHeaderClass: 'w-40',
                hideDialog: true
            }),
            voided: new Field('voided', '', [], false, 'checkbox', {hideDialog: true}),
            IsImported: new Field('IsImported', '', [], false, 'checkbox', {hideDialog: true}),

            // Modal
            Amount: new Field('Amount', '', ['empty'], false, 'float', {hideTable: true}),
            IssuedToID: new Field('IssuedToID', '', ['empty'], false, 'select-search', {hideTable: true}),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {hideTable: true}),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {hideTable: true})
        }
    }

    getQueryFilterFields = () => {
        const initialStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss');
        const initialEndDate = moment().format('YYYY-MM-DD HH:mm:ss')

        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', initialStartDate, [''], false, 'date', {isRequiredFilter: true}),
            EndDate: new Field('EndDate', initialEndDate, [''], false, 'date', {isRequiredFilter: true})
        }
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'query', 'offset'])
    }

    getResource = () => {
        return Resources.MoneyCodesIntegrated
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', []).filter(it => it.code?.toLowerCase().includes(this.state.queryFilterFields.query.value.toLowerCase()) || it.issuedTo?.toLowerCase().includes(this.state.queryFilterFields.query.value.toLowerCase()) || it.notes?.toLowerCase().includes(this.state.queryFilterFields.query.value.toLowerCase()))
        const count = data.length

        const hasEFSIntegration = !!getProp(resource.data, 'integrations.EFS.hasIntegration', 1)

        const noEFSIntegrationMessage = (
            <div>
                No money code provider integrated, to check your current integrations visit
                <Link to={{pathname: '/settings', from: {prevPath: this.props?.location?.pathname}}}
                      className="text-sky-600 hover:underline"> settings page</Link>.
            </div>
        )

        return (
            <Page>
                <PageHeader
                    title={translate('page.heading.IntegratedMoneyCodes')}
                    buttonHidden={!checkPerm(this.getResource(), CREATE_PERM)}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={this.props.dispatch}
                        />
                    )}
                />
                <div className="max-w-fit">
                    <InfoParagraph>
                        {translate('text.listOfMoneyCodesEFS')}
                    </InfoParagraph>
                </div>

                {hasEFSIntegration ? (
                    <div>
                        <div className="flex">
                            <ActiveFilters
                                filterFields={this.state.queryFilterFields}
                                onLabelClick={this.handleFilterInputChange}
                                onClearFiltersClick={() => {
                                    this.handleClearFiltersClick(['StartDate', 'EndDate'])
                                }}
                                translate={translate}
                            />

                            <div className="ml-auto flex">
                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                        </div>

                        <TableCard addClass={'relative'}>
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                onRefreshTable={this.fetchData}
                                isLoading={resource.isLoading}
                            />

                            <ResourceTable
                                data={data}
                                fields={this.state.fields}
                                verticalTableIsVisible={this.props.breakpoint.index <= 1}

                                translate={translate}
                                isLoading={resource.isLoading}

                                options={this.state.tableOptions}

                                limit={!!count ? count : 10}

                                sort={this.state.sort}
                                sortBy={this.state.sortBy}
                                onSortChange={this.handleUpdateSort}

                                actions={[
                                    {
                                        action: (item) => this.handleToggleImportModal(item),
                                        icon: ArrowDownTrayIcon,
                                        visible: (item) => checkPerm(this.getResource(), CREATE_PERM) && !item.IsImported,
                                        title: translate('btn.import'),
                                    }
                                ]}
                            />

                            <NoRecordsTable
                                show={(data.length === 0) && !resource.isLoading}
                                canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                                title={'No matching records found'}
                                text={'Create a new money codes'}
                                btnLabel="Create money codes"
                                onBtnClick={() => this.handleToggleCreateModal()}
                                onClearFilterClick={() => {
                                    this.handleClearFiltersClick(['StartDate', 'EndDate'])
                                }}
                                clearFilterBtnLabel={translate('text.clear_all_filters')}
                                clearFilterText={translate('text.try_without_filters')}
                                filters={this.state.queryFilterFields}
                            />
                        </TableCard>
                    </div>
                ) : !resource.isLoading && (
                    <Card>
                        <InfoBar addClass={'text-base'}>
                            {noEFSIntegrationMessage}
                        </InfoBar>
                    </Card>
                )}

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <ModalSaveResource
                    show={this.state.isImportModalOpen}
                    title={'Import transaction'}
                    widthClass="max-w-md"
                    fields={{
                        IssuedToID: new Field('IssuedToID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                        MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                        LoadNumber: new Field('LoadNumber', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
                    }}
                    translate={translate}
                    onClose={this.handleToggleImportModal}
                    metadata={{
                        IssuedToID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + ' ' + item.LastName
                            })
                        },
                        MoneyCodeTypeID: {
                            api: 'api/' + Resources.MoneyCodeType,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.MoneyCodeTypeID,
                                label: item.Reason
                            })
                        },
                        LoadNumber: {
                            api: 'api/' + Resources.LoadsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (it) => ({
                                label: it.LoadNumber,
                                value: it.LoadID
                            })
                        }
                    }
                    }
                    onSubmit={(params) => {
                        if (params) {
                            params.LoadID = params.LoadNumber
                            delete params.LoadNumber
                            const item = this.state.selectedItem
                            const Date = moment(toFrontDateTimeFromUTC(item.created)).format('YYYY-MM-DD HH:mm:ss')

                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign(params, {
                                    Code: item.code,
                                    Notes: item.notes,
                                    Amount: item.amount,
                                    Date: Date
                                }),
                                resource: Resources.MoneyCodesImport,
                                piggyQuery: this.getQuery(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: `Transaction has been imported`,
                            }))
                            this.handleToggleImportModal()
                        }
                    }}
                >
                </ModalSaveResource>

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </Page>
        )
    }
}

export default connect(state => state)(IntegratedMoneyCodesView)
