import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import Tippy from "@tippyjs/react";
import ArrowsPointingOutIcon from "@heroicons/react/24/outline/ArrowsPointingOutIcon";
import {ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/20/solid";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {HandThumbUpIcon, PaperAirplaneIcon, TrashIcon} from "@heroicons/react/24/outline";
import ThumbUpSolidIcon from "@heroicons/react/20/solid/HandThumbUpIcon";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {
    checkPerm,
    classNames,
    getProp,
    returnTicketBugLevel,
    returnTicketingStatus,
    returnTicketType,
    TicketTypeColor
} from "../../../util/util-helpers";
import ModalFooter from "../modal-footer";
import ModalHeader from "../modal-header";
import ModalConfirm from "../modal-confirm";
import {Loader, LoaderLarge, LoaderSmall} from "../../loader";
import DropZoneAlt from "../../dropzone/drop-zone-alt";
import FieldSelectSearch from "../../fields/field-select-search";
import {ArrowsCollapseIcon} from "../../../../data/themes/icons";
import FieldTextarea from "../../fields/field-textarea";
import Resources from "../../../../data/services/resources";
import {deleteResource, updateResource} from "../../../../data/actions/resource";
import {deleteDocument, download, uploadDocument} from "../../../../data/actions/download";
import LocalStorage from "../../../util/localStorage";
import Modal from "../index";
import FieldText from "../../fields/field-text";
import InfoBar from "../../info-paragraph/info-bar";
import FileList from "../../dropzone/file-list";
import ModalDefault from "../modal-default";
import FileViewer from "../../../components/file-viewer/components";
import {createSecondResource, getSecondResource} from "../../../../data/actions/secondResource";
import {showModal} from "../../../../data/actions/ui";
import {
    BugLevel,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    ticketingStatus,
    UPDATE_PERM
} from "../../../util/util-consts";
import Env from "../../../../util/env";
import {getJWT} from "../../../util/util-auth";
import {cloneDeep, toggleBodyScroll} from "../../../util/util-vanilla";
import {currentDate, isTodayDate, toFrontDateTime} from "../../../util/util-dates";
import Nav from "../../nav";

class TicketingInfoDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                TaskComment: new Field("TaskComment", '', ['empty'])
            },
            history: [],
            editedComment: {
                id: null,
                value: null,
            },
            documents: [],
            documentsToUpload: [],
            commentFilesToUpload: [],
            DocumentTypeID: null,
            activeTab: "comments",
            files: [],
            tabs: this.getTabs(),
            DescErrors: {},
            showAttachments: false,
            previewDocument: false,
            previewDocumentModal: false,
            isDropZoneDraggedOver: false,
            fullScreen: window.fullScreen,
            areDocumentsLoading: true
        };
        this.clickOutsideTextArea = createRef();
        this.dropzoneRef = createRef();

        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.secondResource.isLoading && !this.props.secondResource.isLoading) {
            let objDiv = document.getElementById("commentSection");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }

            this.setState({
                areDocumentsLoading: false,
                history: cloneDeep(getProp(this.props.secondResource, 'data.history', [])).reverse().map((item, index) => {
                    return (
                        <div key={index}>{this.returnTaskStatus(item)}</div>
                    )
                })
            })
        }

        if (
            getProp(this.props.secondResource, "data.ticket", {})?.[0]?.documents?.list?.length
            !== getProp(prevProps.secondResource, "data.ticket", {})?.[0]?.documents?.list?.length
        ) {
            const documents = getProp(this.props.secondResource, "data.ticket", {})?.[0]?.documents?.list ?? [];

            this.setState({
                documents: documents,
                areDocumentsLoading: false
            })
        }

        if (!!this.props?.secondResource?.data?.history && prevProps?.secondResource?.data?.history && prevProps.secondResource.data.history.length !== this.props.secondResource.data.history.length) {
            this.setState({
                history: cloneDeep(getProp(this.props.secondResource, 'data.history', [])).reverse().map((item, index) => {
                    return (
                        <div key={index}>{this.returnTaskStatus(item)}</div>
                    )
                })
            })
        }

        if (!this.props.download.isLoading && prevProps.download.isLoading) {
            this.setState({
                areDocumentsLoading: false
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.id,
            },
            resource: Resources.TicketsInfo
        }))
    };

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
            isFilesUploadDialogOpen: true
        });
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.hideModal()
        }
        if (event.keyCode === 122) {
            this.handleFullScreenChange()
        }
    }

    hideModal = () => {
        this.props.onClose()
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleDocInputChange = (name, value, i) => {
        let descriptions = this.state.Descriptions;
        descriptions[i] = value;
        this.setState({Descriptions: descriptions});
    };

    handleLinkClick = (e) => {
        if (e.includes("http")) {
            window.open(`${e}`, '_blank');
        } else {
            window.open(`${window.location.origin}${e}`, '_blank');
        }
    }

    addCommentHandler = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createSecondResource({
                    user: LocalStorage.get('user'),
                    params: {
                        ParentTicketID: this.props.id,
                        Description: this.state.fields.TaskComment.value
                    },
                    query: {
                        id: this.props.id
                    },
                    resource: Resources.Tickets,
                    piggyResource: Resources.TicketsInfo,
                    errorMessage: true, successMessage: `You successfully added comment`,
                    file: this.state.commentFilesToUpload,
                    fileResource: Resources.TicketsImages,
                    fileParams: {}
                }));
                this.handleInputChange("TaskComment", "")
                this.setState({commentFilesToUpload: []});
            }
        });
    }

    confirmDeleteCommentHandler = (id) => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                id: id
            },
            secondQuery: {
                id: this.props.id
            },
            resource: Resources.Tickets,
            piggySecondResource: Resources.TicketsInfo,
            errorMessage: true, successMessage: `You successfully deleted comment`,
        }));
    }

    deleteCommentDocument = (documentIndex) => {
        let commentFilesToUpload = this.state.commentFilesToUpload;
        commentFilesToUpload.splice(documentIndex, 1);

        this.setState({
            commentFilesToUpload: commentFilesToUpload
        });
    }

    editComment = (id, value) => {
        this.setState({editedComment: {id: id, value: value}}, () => {
            document.getElementById("textInput").focus();
        })
    }

    setActiveTab = tab => this.setState({activeTab: tab});

    editCommentHandler = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                id: this.state.editedComment.id,
                Description: this.state.editedComment.value,
            },
            query: {
                id: this.props.id
            },
            resource: Resources.Tickets,
            piggySecondResource: Resources.TicketsInfo,
            errorMessage: true, successMessage: `Comment updated`
        }));
        this.setState({editedComment: {id: null, value: null,}})
    }

    uploadDocument = () => {
        const errors = this.state.Descriptions.reduce((memo, it, i) => {
            if (!it) {
                memo[i] = true;
            }
            return memo;
        }, {});
        this.setState({
            DescErrors: errors
        });
        if (Object.keys(errors).length === 0) {
            this.props.dispatch(uploadDocument({
                user: LocalStorage.get("user"),
                files: this.state.documentsToUpload,
                id: this.props.id,
                query: {
                    id: this.props.id,
                },
                Descriptions: this.state.Descriptions,
                resource: Resources.TicketsImages,
                piggySecondResource: Resources.TicketsInfo
            }))
            this.setState({
                areDocumentsLoading: true,
                isFilesUploadDialogOpen: false,
                Descriptions: [],
                documentsToUpload: []
            })
        }
    }

    previewDocument = (document) => {
        const file = this.state.documents.find(it => it.PhysicalLocation === document.path);
        if (file) {
            this.setState({previewDocument: file, previewDocumentModal: true});
        }
    }

    previewCommentDocument = (list, document) => {
        const file = list.find(it => it.DocumentName === document.path);
        if (file) {
            this.setState({previewDocument: file, previewDocumentModal: true});
        }
    }

    cancelUpload = () => {
        this.setState({
            Descriptions: [],
            files: [],
            DocumentTypeID: null,
            isFilesUploadDialogOpen: false
        })
    }

    closePreview = () => {
        this.setState({previewDocument: null, previewDocumentModal: false});
    }

    downloadDocument = () => {
        const fileExt = this.state.previewDocument?.OriginalFilename?.split('.').pop();
        this.props.dispatch(download({
            user: LocalStorage.get("user"),
            resource: Resources.TicketsImages,
            query: Object.assign({
                DocumentID: this.state.previewDocument.DocumentID,
                format: fileExt,
                name: "document_" + currentDate() + "." + fileExt
            })
        }))
        this.setState({previewDocument: false, previewDocumentModal: false}, () => this.closePreview())
    }

    handleToggleConfirmDialog = (item = null) => {
        const file = this.state.documents.find(it => it.PhysicalLocation === item?.path);

        this.setState({
            selectedDocument: file,
            confirmDialog: !this.state.confirmDialog
        });
    }

    openDeleteCommentAttachmentDialog = (list, item = null) => {
        const file = list.find(it => it.PhysicalLocation === item?.path);

        this.setState({
            selectedDocument: file,
            confirmDialog: !this.state.confirmDialog
        });
    }

    deleteDocument = () => {
        this.setState({
            areDocumentsLoading: true,
            confirmDialog: false
        }, () => {
            this.props.dispatch(deleteDocument({
                user: LocalStorage.get("user"),
                query: {
                    id: this.props.id
                },
                params: {
                    DocumentID: this.state.selectedDocument.DocumentID
                },
                resource: Resources.TicketsImages,
                piggySecondResource: Resources.TicketsInfo
            }))
        });
    }

    returnTaskStatus = item => {
        if (item.ChangeType == "Status") {
            return (
                <div className='mb-2 flex'>
                    <span>
                        <img
                            onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.UpdatedByContactID}))}
                            style={{width: "40px", height: "40px", objectFit: "contain"}}
                            src={item.ContactImagePath ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                token: getJWT().access_token,
                                ContactID: item.UpdatedByContactID
                            }) : "/images/avatar.png"} alt={item.ContactImagePath}
                            className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall mr-2"
                        />
                    </span>

                    <span>
                       <span className="space-x-1">
                            <span className='font-weight-bold'>{item.FirstName} {item.LastName}</span>
                            <span>changed status from</span>
                            <span>&apos;{ticketingStatus[item.OldValue]}&apos; to &apos;{ticketingStatus[item.NewValue]}&apos;.</span>
                       </span>

                        <div className='text-tm-gray-500'>{toFrontDateTime(item.CreateUpdateDate)}</div>
                    </span>
                </div>
            )
        }
        if (item.ChangeType == "Level") {
            return (
                <div className='mb-2 flex'>
                    <a href="#">
                        <img
                            onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.UpdatedByContactID}))}
                            style={{width: "40px", height: "40px", objectFit: "contain"}}
                            src={item.ContactImagePath ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                token: getJWT().access_token,
                                ContactID: item.UpdatedByContactID
                            }) : "/images/avatar.png"} alt={item.ContactImagePath}
                            className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall mr-2"/>
                    </a>

                    <span>
                       <span className="space-x-1">
                            <span className='font-weight-bold'>{item.FirstName} {item.LastName} </span>
                            <span>changed level from</span>
                            <span>&apos;{BugLevel[item.OldValue]}&apos; to &apos;{BugLevel[item.NewValue]}&apos;.</span>
                       </span>

                        <div className='text-tm-gray-500'>{toFrontDateTime(item.CreateUpdateDate)}</div>
                    </span>
                </div>
            )
        }
        if (item.ChangeType == "Created") {
            return (
                <div className='mb-2 flex'>
                    <a href="#">
                        <img
                            onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.UpdatedByContactID}))}
                            style={{width: "40px", height: "40px", objectFit: "contain"}}
                            src={item.ContactImagePath ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                token: getJWT().access_token,
                                ContactID: item.UpdatedByContactID
                            }) : "/images/avatar.png"} alt={item.ContactImagePath}
                            className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall mr-2"/>
                    </a>

                    <span>
                       <span className="space-x-1">
                            <span className='font-weight-bold'>{item.FirstName} {item.LastName}</span>
                            <span>created ticket {item.TicketsID}.</span>
                       </span>

                        <div className='text-tm-gray-500'>{toFrontDateTime(item.CreateUpdateDate)}</div>
                    </span>
                </div>
            )
        }
    }

    likeTicketHandler = (type) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: {
                TicketsID: this.props.id,
                TicketTypeID: type
            },
            query: {
                id: this.props.id
            },
            resource: Resources.TicketsLike,
            piggySecondResource: Resources.TicketsInfo
        }))
    }

    changeTicketStatusHandler = (value, oldValue, keyHandler) => {
        if (oldValue != value) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                params: {
                    OldValue: oldValue,
                    [`${keyHandler}`]: +value,
                    TicketsID: this.props.id
                },
                query: {
                    id: this.props.id
                },
                errorMessage: true,
                successMessage: this.props.translate("text.TicketStatusUpdatedTo", [ticketingStatus[value]]),
                resource: Resources.TicketsInfo,
                piggySecondResource: Resources.TicketsInfo
            }))
        }
    }

    removeFileFromState = index => {
        let arr = this.state.files
        if (index > -1) {
            arr.splice(index, 1);
        }
        this.setState({files: arr})
    }

    returnComment = (comment, i) => {
        if (comment.length < 200) {
            return comment
        } else {
            let tmp = comment.slice(0, 200)
            if (this.state[`showSliced${i}`]) {
                return (
                    <div>
                        {comment}
                        <br/>

                        <div
                            onClick={() => this.setState({[`showSliced${i}`]: !this.state[`showSliced${i}`]})}
                            className='font-weight-bolder mt-1 cursor-pointer hover-underline'>
                            {this.props.translate("text.ShowLess")}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        {tmp}...
                        <br/>
                        <div
                            onClick={() => this.setState({[`showSliced${i}`]: !this.state[`showSliced${i}`]})}
                            className='font-weight-bolder mt-1 cursor-pointer hover-underline'>
                            {this.props.translate("text.ReadMore")}
                        </div>
                    </div>
                )
            }
        }
    }

    handleFullScreenChange = () => {
        let modal = document.getElementById('modal')
        if (modal) {
            if (document.fullscreenElement) {
                document.exitFullscreen().then(() => this.setState({fullScreen: false}));
            } else {
                modal.requestFullscreen().then(() => this.setState({fullScreen: true}));
            }
        }
    }

    /** Helpers
     ================================================================= */
    handleTabChange = (selectedTab) => {
        let tabs = this.state.tabs;

        this.setState({
            tabs: tabs.map(it => {
                it.current = it.key === selectedTab;

                return it;
            })
        })
    }

    getTabs = () => {
        return [
            {
                name: this.props.translate("tab.comments"),
                key: "comments",
                current: true
            },
            {
                name: this.props.translate("tab.history"),
                key: "history",
                current: false
            }
        ]
    }

    render() {
        const {translate, secondResource} = this.props;
        const {areDocumentsLoading} = this.state;
        const items = getProp(secondResource, "data.ticket", {})
        const hasDocuments = !!this.state.documents.length;
        const selectedTab = this.state.tabs.find(it => it.current);

        const comments = getProp(secondResource, "data.ticket", []).map((item, index) => {
            let isMe = item.ContactID == LocalStorage.get("user")?.Contact?.ContactID

            if (index > 0) {
                return (
                    <div
                        key={index}
                        className='border-b border-tm-gray-300 mt-3'
                    >
                        <div
                            className="flex"
                        >
                            <div className="flex-shrink-0">
                                <img
                                    onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                                    src={item.ContactImagePath ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                        token: getJWT().access_token,
                                        ContactID: item.ContactID
                                    }) : "/images/avatar.png"} alt={item.ContactImagePath}
                                    className="w-10 h-10 object-contain cursor-pointer border-0 rounded-full list-thumbnail align-self-center xsmall"/>
                            </div>

                            <div className="pl-3 grow w-full">
                                <a href="#">
                                    <p
                                        onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                                        className="font-weight-medium mb-0"> {item.FirstName} {item.LastName}</p>
                                    <p className="text-tm-gray-500 mb-0 text-small">{isTodayDate(item.CreateDate, translate)}</p>
                                </a>
                                <p className="my-2">
                                    {item.ArchivedDate == null ?
                                        <span>
                                    {item.TicketsID != this.state.editedComment.id && this.returnComment(item.Description, index)}
                                            {item.UpdateDate == null ? "" : <span
                                                className='text-tm-gray-500 text-small font-weight-bolder has-link has-link-element'> ({translate("text.edited").toUpperCase()} {item.UpdateDate ? ` - ${isTodayDate(item.UpdateDate, translate)}` : ""})</span>}
                                    </span>
                                        :
                                        <span
                                            className='text-tm-gray-500 text-small font-italic font-weight-bolder has-link has-link-element'>[{translate("text.message_deleted")}]</span>
                                    }
                                    {item.TicketsID == this.state.editedComment.id && (
                                        <div ref={this.clickOutsideTextArea}>
                                            <div className="row">
                                                <div className="col">
                                                    <label className="label-stack">
                                                        {translate("text.Edit")}
                                                    </label>

                                                    <FieldTextarea
                                                        id={"textInput"}
                                                        onChange={(name, value) => this.setState({
                                                            editedComment: {
                                                                id: item.TicketsID,
                                                                value: value
                                                            }
                                                        })}
                                                        value={this.state.editedComment.value}
                                                        placeholder={""} addClass={"form-control whitespace-pre-wrap"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex justify-end items-center ml-auto mt-4">
                                                <button
                                                    className={`btn btn-primary mr-2`}
                                                    onClick={this.editCommentHandler}
                                                >
                                                    {translate("btn.save")}
                                                </button>

                                                <button
                                                    className="btn btn-outline"
                                                    onClick={() => {
                                                        this.setState({
                                                            editedComment: {
                                                                id: null,
                                                                value: null,
                                                            },
                                                        })
                                                    }}
                                                >
                                                    {translate("btn.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </p>
                            </div>
                            <div className="">
                                {!!isMe && item.ArchivedDate == null && (
                                    <div className="flex">
                                        <button
                                            className="btn-table-action p-1"
                                            onClick={() => {
                                                this.editComment(item.TicketsID, item.Description)
                                            }}
                                        >
                                            <PencilIcon className="w-5 h-5"/>
                                        </button>

                                        <button
                                            className="btn-table-action p-1"
                                            onClick={() => {
                                                this.confirmDeleteCommentHandler(item.TicketsID)
                                            }}
                                        >
                                            <TrashIcon className="w-5 h-5"/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {item.documents.list.length > 0 && item.ArchivedDate == null && (
                            <div className="text-tm-gray-500 cursor-pointer mt-4 mb-2" onClick={() => {
                                this.setState({[`showAttachments${index}`]: !this.state[`showAttachments${index}`]}, () => {
                                    let elem = document.getElementById(`showAttachments${index}`)
                                    if (elem) {
                                        elem.scrollIntoView({
                                            behavior: 'smooth'
                                        });
                                    }
                                })
                            }}>
                                <div className="flex items-center">
                                    {translate("text.Attachments")}
                                    ({item.documents.list.length})

                                    {!this.state[`showAttachments${index}`] && (
                                        <ChevronUpIcon className="w-5 h-5 text-tm-gray-600"/>
                                    )}

                                    {!!this.state[`showAttachments${index}`] && (
                                        <ChevronDownIcon className="w-5 h-5 text-tm-gray-600"/>
                                    )}
                                </div>
                            </div>
                        )}
                        {!!this.state[`showAttachments${index}`] && item.ArchivedDate == null && (
                            <div id={`showAttachments${index}`}>
                                <FileList
                                    className="flex space-x-4 mb-4"
                                    itemClass="rounded-card border border-tm-gray-200 flex w-64 py-1 px-3"
                                    filePaths={item.documents.list.reduce(
                                        (memo, file) => {
                                            memo.push(file.DocumentName);
                                            return memo;
                                        }, []
                                    )}
                                    onFileDelete={(file) => this.openDeleteCommentAttachmentDialog(item.documents.list, file)}
                                    onFileView={(file) => this.previewCommentDocument(item.documents.list, file)}
                                    hideFileDelete={!isMe}
                                />
                            </div>
                        )}
                    </div>
                )
            }
        })

        return (
            <div id={"modal"} className={"bg-inverse"}>
                <ModalHeader
                    title={<span>{this.props.translate("text.Ticket_info")} <span
                        className='text-tm-gray-500'> - {this.props.id}</span></span>}
                    onClose={this.hideModal}
                >
                    <div className="w-full text-right px-4">
                        <button
                            onClick={() => this.handleFullScreenChange()}
                            className="btn btn-icon">
                            {!this.state.fullScreen && (
                                <Tippy content={<span>FullScreen</span>}>
                                    <ArrowsPointingOutIcon className="w-5 h-5"/>
                                </Tippy>
                            )}

                            {!!this.state.fullScreen && (
                                <Tippy content={<span>Exit FullScreen</span>}>
                                    <ArrowsCollapseIcon className="w-5 h-5"/>
                                </Tippy>
                            )}
                        </button>
                    </div>
                </ModalHeader>

                <div className="p-6 text-tm-gray-900 relative">

                    {this.props.secondResource.isLoading && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    <div className="grid grid-cols-12 gap-8 mb-8">
                        <div className="col-span-6">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <div className="label-stack">{translate("table.ticket_title")}</div>
                                    <div
                                        className="form-control leading-5 bg-tm-gray-50 flex items-center">{items[0]?.Title}</div>
                                </div>

                                {checkPerm(Resources.TicketsInfo, UPDATE_PERM) ? (
                                        <div className="col-span-6">
                                            <label className="label-stack">
                                                <span>{translate("field.Status")}</span>
                                            </label>

                                            <FieldSelectSearch
                                                className="form-control"
                                                omitSort
                                                values={ticketingStatus}
                                                onChange={(name, value) => this.changeTicketStatusHandler(value, items[0]?.TicketStatusID, "TicketStatusID")}
                                                placeholder={translate("field.Status")}
                                                addClass={"form-control"}
                                                value={items[0]?.TicketStatusID}
                                            />
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="col-6">
                                            <p className="text-tm-gray-500 text-small mb-2">{translate("table.Status")}</p>
                                            <p className="mb-3 font-weight-bold">{translate(returnTicketingStatus(items[0]?.TicketStatusID))}</p>
                                        </div>
                                    )
                                }

                                <div className="col-span-6">
                                    <label className="label-stack">
                                        {translate("table.ticket_type")}
                                    </label>

                                    <div className="form-control bg-tm-gray-50 flex items-center gap-x-1">
                                        {
                                            items[0]?.TicketTypeID == 2
                                                ? <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                       viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                       className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"/>
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                       viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                       className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082"/>
                                                </svg>
                                        }

                                        <p className="">
                                            {translate(returnTicketType(items[0]?.TicketTypeID))}
                                        </p>
                                    </div>
                                </div>

                                {items[0]?.TicketTypeID == 2 && (
                                    <div className="col-span-6">
                                        <label className="label-stack">
                                            <span>{translate("field.like_this_suggestion")}</span>
                                        </label>

                                        <button
                                            onClick={() => this.likeTicketHandler(1)}
                                            className="form-control items-center flex h-8 p-0 hover:bg-primary-transparent hover:text-primary group"
                                        >
                                            <div
                                                className="flex items-center h-10 w-8 px-2 border-r border-tm-gray-200 group-focus:border-primary">
                                                {!items[0].liked && (
                                                    <HandThumbUpIcon
                                                        className={
                                                            classNames(
                                                                "w-5 h-5",
                                                                items[0].liked ? "text-primary" : "text-tm-gray-600 group-hover:text-primary"
                                                            )
                                                        }
                                                    />
                                                )}

                                                {!!items[0].liked && (
                                                    <ThumbUpSolidIcon
                                                        className={
                                                            classNames(
                                                                "w-5 h-5",
                                                                items[0].liked ? "text-primary" : "text-tm-gray-600 group-hover:text-primary"
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="px-2">
                                                {items[0]?.likes} {translate("text.likes")}
                                            </div>
                                        </button>
                                    </div>
                                )}

                                {items[0]?.TicketLevelID && (
                                    checkPerm(Resources.TicketsInfo, UPDATE_PERM) ? (
                                            <div className="col-span-6">
                                                <label className="label-stack">
                                                    <span>{translate("field.Level")}</span>
                                                </label>

                                                <FieldSelectSearch
                                                    className="form-control"
                                                    values={BugLevel}
                                                    omitSort
                                                    onChange={(name, value) => this.changeTicketStatusHandler(value, items[0]?.TicketLevelID, "TicketLevelID")}
                                                    placeholder={translate("field.Level")}
                                                    addClass={"form-control"}
                                                    value={items[0]?.TicketLevelID}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            <div className='col-span-6'>
                                                <p className="label-stack">{translate("table.bug_level")}</p>
                                                <div className='flex my-2'>
                                                    <p className="mb-3 font-weight-bold">{translate(returnTicketBugLevel(items[0]?.TicketLevelID))}</p>
                                                    <Tippy content={
                                                        <span>{translate(returnTicketBugLevel(items[0]?.TicketLevelID))}</span>}>
                                                        {items[0]?.TicketLevelID > 2 ? (<ArrowUpIcon
                                                            style={{color: TicketTypeColor(items[0]?.TicketLevelID)}}
                                                            className={"w-4 h-4 mx-2"}/>) : (<ArrowDownIcon
                                                            style={{color: TicketTypeColor(items[0]?.TicketLevelID)}}
                                                            className={"w-4 h-4 mx-2"}/>)}
                                                    </Tippy>
                                                </div>
                                            </div>
                                        )

                                )}

                                {!!items[0]?.Description && (
                                    <div className="col-span-full">
                                        <p className="label-stack">{translate("table.description")}</p>
                                        <div
                                            className="bg-tm-gray-50 form-control h-24 overflow-y-scroll flex items-center">{items[0]?.Description}</div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="col-span-6">
                            <label className="label-stack">{translate("tab.attachments")}</label>

                            <div className="relative rounded-card border border-tm-gray-200 h-32">
                                <DropZoneAlt
                                    className="w-full pb-7 px-6 h-32 relative z-0"
                                    maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                    maxFilesAccepted={10}
                                    accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                    translate={translate}
                                    onAcceptFiles={(acceptedFiles) => {
                                        this.setState({
                                            documentsToUpload: acceptedFiles.map(file => Object.assign(file, {
                                                preview: URL.createObjectURL(file)
                                            })),
                                            Descriptions: acceptedFiles.map(() => ""),
                                            isFilesUploadDialogOpen: true,
                                        });
                                    }}
                                    content={(isDragAccept, isDragReject) => {
                                        return (
                                            <React.Fragment>
                                                <div
                                                    className={
                                                        classNames(
                                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                            "pb-4 w-full h-full absolute rounded-card top-0 left-0 flex items-center justify-center"
                                                        )}
                                                >
                                                    <p className="text-lg text-tm-gray-700">Drag and drop document
                                                        files
                                                        here</p>
                                                </div>

                                                <div
                                                    className={
                                                        classNames(
                                                            "absolute bottom-0 px-6 left-0 right-0 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent"
                                                        )}
                                                >
                                                    Attach documents by dragging and dropping or <span
                                                    className="text-primary-tint font-semibold">click here</span> to
                                                    select them.
                                                </div>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            </div>

                            <div className="mt-6 space-y-4">
                                {areDocumentsLoading && (
                                    <Loader disableContainer={true}/>
                                )}

                                {hasDocuments && !areDocumentsLoading && (
                                    <FileList
                                        filePaths={this.state.documents.reduce(
                                            (memo, file) => {
                                                memo.push(file.PhysicalLocation);
                                                return memo;
                                            }, []
                                        )}
                                        onFileDelete={(file) => this.handleToggleConfirmDialog(file)}
                                        onFileView={(file) => this.previewDocument(file)}
                                        hideFileDelete={items[0]?.ContactID == LocalStorage.get("user")?.Contact?.ContactID ? false : true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <Nav tabs={this.state.tabs} onTabChange={this.handleTabChange}/>

                    {selectedTab.key === "comments" && !this.props.secondResource.isLoading && (
                        <div className="pt-6">
                            <div className="tab-pane fade active show" id="second" role="tabpanel"
                                 aria-labelledby="second-tab">
                                <div className="comment-section" id='commentSection'>
                                    {comments}
                                </div>
                            </div>

                            <div className="my-2">
                                <div className="label-stack">{translate("field.Comment")}</div>

                                <div
                                    className="relative pb-7 border border-tm-gray-200 rounded-card"
                                    onDragEnter={() => {
                                        this.setState({
                                            isDropZoneDraggedOver: true
                                        })
                                    }}
                                >
                                    <FieldTextarea
                                        rows={4}
                                        onChange={this.handleInputChange} {...this.state.fields.TaskComment}
                                        placeholder={""}
                                        addClass={
                                            classNames(
                                                this.state.isDropZoneDraggedOver ? "z-0" : "z-20",
                                                "relative form-control rounded-b-none border-none whitespace-pre-wrap"
                                            )}
                                    />

                                    <DropZoneAlt
                                        className={
                                            classNames(
                                                "absolute inset-0",
                                                this.state.isDropZoneDraggedOver ? "z-10" : undefined
                                            )
                                        }
                                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                        maxFilesAccepted={10}
                                        accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                        translate={translate}
                                        onAcceptFiles={(acceptedFiles) => {
                                            this.setState({
                                                commentFilesToUpload: acceptedFiles.map(file => Object.assign(file, {
                                                    preview: URL.createObjectURL(file),
                                                    Description: ''
                                                })),
                                                canSubmit: true,
                                                isDropZoneDraggedOver: false
                                            });
                                        }}
                                        content={(isDragAccept, isDragReject) => {
                                            return (
                                                <React.Fragment>
                                                    <div
                                                        onDragLeave={() => this.setState({isDropZoneDraggedOver: false})}
                                                        className={
                                                            classNames(
                                                                isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                                isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                                !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                                "pb-4 w-full h-full absolute rounded-card top-0 left-0 flex items-center justify-center"
                                                            )}
                                                    >

                                                    </div>

                                                    <div
                                                        className={
                                                            classNames(
                                                                "absolute bottom-0 px-6 h-7 left-0 right-0 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent rounded-b-card",
                                                                this.state.isDropZoneDraggedOver ? "-z-10" : undefined
                                                            )}
                                                    >
                                                        Attach documents by dragging and dropping or <span
                                                        className="text-primary-tint font-semibold">click here</span> to
                                                        select them.
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                </div>

                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <div>
                                        {!!this.state.commentFilesToUpload.length && (
                                            <div className="mb-2">
                                                <InfoBar>Sending a comment will attach following documents: </InfoBar>
                                            </div>
                                        )}
                                        <FileList
                                            files={this.state.commentFilesToUpload}
                                            onFileDelete={(file, i) => this.deleteCommentDocument(i)}
                                        />
                                    </div>

                                    <div className="text-right">
                                        <Tippy
                                            content={translate("btn.send")}
                                        >
                                            <button
                                                className="mx-1 bg-primary-tint p-2 rounded-full text-white hover:bg-primary focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                                onClick={() => {
                                                    this.addCommentHandler();
                                                }}
                                            >
                                                <PaperAirplaneIcon className="w-5 h-5"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                    {selectedTab.key === "history" && (
                        <div className="pt-6">
                            {!secondResource.isLoading && this.state.history}

                            {(this.state.history.length === 0) && !secondResource.isLoading && (
                                <span>{translate("text.no_history")}</span>
                            )}
                        </div>
                    )}
                    {this.props.secondResource.isLoading && (
                        <div className="absolute position-absolute-center">
                            <LoaderSmall/>
                        </div>
                    )}
                </div>
                <ModalFooter
                    closeButtonLabel={translate("btn.close")}
                    onClose={this.hideModal}
                />

                <ModalDefault
                    show={!!this.state.isFilesUploadDialogOpen}
                    widthClass={"max-w-5xl"}
                    translate={translate}
                    title={translate("modal_heading.upload_confirm")}
                    onClose={this.cancelUpload}
                    closeButtonLabel={translate("btn.close")}
                    buttonLabel={translate("btn.Upload")}
                    onButtonClick={this.uploadDocument}
                >
                    <div className="p-4">
                        {this.state.documentsToUpload.map((it, i) => {
                            return (
                                <div
                                    key={it?.preview}
                                    className="form-group mb-3"
                                >
                                    <label
                                        className={'break-all text-sm text-tm-gray-900'}>{translate("text.desc_for_file")}: {this.state.documentsToUpload[i].name}</label>
                                    <FieldText
                                        addClass="form-control"
                                        onChange={(name, value) => this.handleDocInputChange(name, value, i)}
                                        value={it.Description}
                                        placeholder=""
                                        errorMessage={!!this.state.DescErrors[i]}
                                    />
                                    <div className="col-md-3"/>
                                </div>
                            )
                        })}
                    </div>
                </ModalDefault>

                <Modal
                    show={this.state.previewDocumentModal}
                    widthClass={"max-w-7xl"}
                    translate={translate}
                    onClose={() => this.setState({previewDocument: null, previewDocumentModal: false})}
                >
                    <ModalHeader
                        className="border-tm-gray-200 border-b text-tm-gray-900"
                        title={this.state.previewDocument?.OriginalFilename}
                        onClose={() => this.setState({previewDocument: null, previewDocumentModal: false})}
                    />
                    <div className="h-dialog-body">
                        <FileViewer
                            fileType={this.state.previewDocument?.OriginalFilename?.split('.').pop()}
                            filePath={Env.getApiUrl("api/" + Resources.TicketsImages, {
                                DocumentID: this.state.previewDocument?.DocumentID,
                                token: getJWT().access_token
                            })}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    </div>

                    <ModalFooter
                        closeButtonLabel={translate("btn.cancel")}
                        onClose={() => {
                            this.setState({previewDocument: null, previewDocumentModal: false})
                        }}
                        buttonLabel={translate("btn.download")}
                        onButtonClick={this.downloadDocument}
                    />
                </Modal>

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmDialog}
                    text={this.props.translate("message.confirm_delete_document")}
                    onClose={this.handleToggleConfirmDialog}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.deleteDocument}
                />
            </div>
        );
    }
}

export default connect(state => state)(TicketingInfoDialog);
