const resourceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'RESET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null,
                errorStatus: null,
                showRedirectDialog: false
            });
        case 'GET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                errorStatus: null,
                showRedirectDialog: false,
                resource: action.data.resource
            });
        case 'GET_RESOURCE_SILENT':
            return Object.assign({}, state, {
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorStatus: action.data.status,
                errorMessage: action.data.data
            });
        case 'GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'CREATE_RESOURCE_DATA_FORM':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: action.data.showRedirectDialog
            });
        case 'ERROR_CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_MULTIPLE_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null,
                updated: action.data?.id
            });
        case 'ERROR_UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_INFINITE_RESOURCE':
            return Object.assign({}, state, {
                isLoading: action.data.reset,
                isInfiniteLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_INFINITE_RESOURCE':
            return Object.assign({}, state, {
                data: Object.assign({}, action.data, {
                    count: action.data.count,
                    list: (!!state.data && state.data.list && !action.data.reset) ? state.data.list.concat(action.data.list) : action.data.list
                }),
                isLoading: false,
                isInfiniteLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_INFINITE_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                isInfiniteLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'RESET_CREATE_UPDATE_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                updated: false
            })
        default:
            return state;

        case 'STORE_RESOURCE_TEMP_DATA':
            return Object.assign({}, state, {
                temp: action.data
            });

        case 'DELETE_RESOURCE_TEMP_DATA':
            return Object.assign({}, state, {
                temp: undefined
            });
    }
};

export default resourceReducer;
