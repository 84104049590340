import React from "react";
import {Switch} from "@headlessui/react";
import {classNames} from "../../../util/util-helpers";

export default function FieldSwitch({name, value, label, disabled, onChange, innerRef, addClass}) {
    return (
        <Switch
            ref={innerRef}
            checked={!!value}
            disabled={disabled}
            onChange={() => onChange(name, !value)}
            className={classNames(
                !!value && !disabled ? 'bg-primary' : undefined,
                !!value && !!disabled ? 'opacity-50 bg-primary cursor-default' : undefined,
                !value && !disabled ? 'bg-tm-gray-200 hover:bg-tm-gray-300' : undefined,
                !value && !!disabled ? "bg-tm-gray-200 opacity-75 cursor-default" : "",
                'relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75',
                addClass
            )}
        >
            <span className="sr-only">{label}</span>

            <span
                className={
                    classNames(
                        value ? 'translate-x-6 bg-white' : 'translate-x-0 bg-white',
                        disabled ? 'bg-tm-gray-100' : undefined,
                        'pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out'
                    )
                }
            />
        </Switch>
    )
}