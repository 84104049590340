import React from "react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../../util/util-helpers";
import Modal from "../index";

export default function ModalWarning(props) {
    const {
        show,
        title,
        text,
        textClassName,
        onConfirm,
        buttonLabel,
        buttonDisabled,
        closeButtonLabel,
        onClose,
        secondaryButtonLabel,
        onSecondaryButtonClick,
        secondaryButtonDisabled,
        width,
    } = props;

    const getIcon = () => {
        let icon, iconBg;

        iconBg = ""
        icon = <div>
            <div className={"flex justify-center text-black"}><ExclamationTriangleIcon className={"h-8 w-8"}/></div>
            <div className={"flex right-3 font-bold text-black justify-center"}>WARNING</div>
        </div>


        return (
            <div
                className={classNames(iconBg, "flex-shrink-0 flex h-18 w-18 rounded-xl justify-center")}>
                {icon}
            </div>
        )
    }
    return (
        <Modal
            close={onClose}
            widthClass={getDialogWidth(width)}
            show={show}
            modalClass={"text-tm-gray-900 w-full bg-yellow-400 inline-block align-bottom bg-popup rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"}
            {...props}
        >
            <div className="sm:items-start p-6">
                <div className={"flex justify-center"}>{getIcon()}</div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4  w-[calc(100%-5rem)]">
                    <h3 className="ml-12 text-lg font-medium text-black font-bold">
                        {title}
                    </h3>

                    <div className="mt-2 ml-12 text-left text-black">
                        {text && (
                            <p className={textClassName ?? "text-md text-black truncate"}
                               dangerouslySetInnerHTML={{__html: text}}/>
                        )}

                        {!!show && props.children}
                    </div>
                </div>
            </div>

            <footer
                className="min-h-[4rem] flex flex-wrap bg-yellow-400 flex-row-reverse items-center px-6 py-2 gap-3 bg-popup rounded-b-lg">
                {(!!buttonLabel || !!closeButtonLabel) && (
                    <div>

                        {!!buttonLabel && (
                            <button
                                data-name="submit-button-shortcut"
                                className={`btn btn-outline bg-primary text-white mr-2`}
                                onClick={() => {
                                    if (!buttonDisabled) {
                                        onConfirm();
                                    }
                                }}
                                disabled={!!buttonDisabled}
                            >
                                {buttonLabel}
                            </button>
                        )}

                        {!!closeButtonLabel && (
                            <button
                                data-name="cancel-button-shortcut"
                                className="btn btn-outline bg-inverse text-tm-gray-900"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {props.closeButtonLabel}
                            </button>
                        )}
                    </div>
                )}

                <span className="flex mr-auto">
                {!!secondaryButtonLabel && (
                    <button
                        className={'btn btn-outline mr-auto'}
                        onClick={() => {
                            if (!secondaryButtonDisabled) {
                                onSecondaryButtonClick();
                            }
                        }}
                        disabled={!!secondaryButtonDisabled}
                    >
                        {secondaryButtonLabel}
                    </button>
                )}

                    {props.children}
            </span>
            </footer>
        </Modal>
    );
}

const getDialogWidth = (width) => {
    switch (width) {
        case "md":
            return "max-w-md";
        case "xl":
            return "max-w-xl";
        case "2xl":
            return "max-w-2xl";
        case "3xl":
            return "max-w-3xl";
        case "4xl":
            return "max-w-4xl";
        case "5xl":
            return "max-w-5xl";
        case "6xl":
            return "max-w-6xl";
        case "7xl":
            return "max-w-7xl";
        default:
            return "max-w-xl"
    }
}
