import {Disclosure} from "@headlessui/react";
import {Link, useHistory} from "react-router-dom";
import React from "react";
import {
    ChartBarIcon,
    ClipboardDocumentListIcon,
    CurrencyDollarIcon,
    HomeIcon,
    IdentificationIcon,
    MapIcon,
    PaperAirplaneIcon,
    PhoneIcon,
    PuzzlePieceIcon,
    QueueListIcon,
    ShieldCheckIcon, UsersIcon,
    WrenchScrewdriverIcon
} from '@heroicons/react/24/outline'
import mainMenu from "../../../../assets/menus/menu-main.json"
import {checkPerm, classNames, getProp} from "../../../util/util-helpers";
import TruckIcon from "@heroicons/react/24/outline/TruckIcon";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import Resources from "../../../../data/services/resources";
import {READ_PERM} from "../../../../util/util-constants";
import {checkFeatureFlag} from "../../feature-flags";
import {useRouteMatch} from "react-router";
import LocalStorage from "../../../util/localStorage";
import {LINE_OF_BUSINESS_PRIMARY_CARRIER, LINE_OF_BUSINESS_PRIMARY_DRIVER} from "../../../util/util-consts";
import {getUser} from "../../../util/util-auth";

export default function MainMenu() {
    const history = useHistory();
    const match = useRouteMatch();
    const currentPath = match.path;

    const iconList = {
        HomeIcon,
        ChartBarIcon,
        TruckIcon,
        ClipboardDocumentListIcon,
        CurrencyDollarIcon,
        IdentificationIcon,
        MapIcon,
        BuildingOfficeIcon,
        PaperAirplaneIcon,
        PhoneIcon,
        ShieldCheckIcon,
        Cog6ToothIcon,
        QueueListIcon,
        WrenchScrewdriverIcon,
        PuzzlePieceIcon,
        UsersIcon
    };

    function checkIfMenuIsVisible(item) {
        if (item?.conditionalVisibility) {
            if (
                item.conditionalVisibility.includes("isCarrier")
                && !getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER)
            ) {
                return false;
            }

            if (
                item.conditionalVisibility.includes("isBroker")
                && !getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER)
            ) {
                return false;
            }

            if (
                item.conditionalVisibility.includes("ScopeAdmin")
                && getUser('scope') !== 'support_admin'
            ) {
                return false;
            }
        }

        if (!item?.children) {
            // Return the default value if it has no children

            const perm = item?.perm ?? [];

            const hasPerm = perm.length === 0 || !!perm.find(perm => {
                return Resources[perm]
                    ? checkPerm(Resources[perm], READ_PERM)
                    : false
            });

            return hasPerm && item.isVisible;
        }

        // Find at least one child
        return !!item?.children.find(child => {
            return checkIfChildIsVisible(child);
        });
    }

    function checkIfChildIsVisible(child) {
        let hasPerm = false;
        let isAuthorized = true;
        if (child.isVisible) {
            if (child.authorizedFlags) {
                isAuthorized = child.authorizedFlags.find(flag => {
                    return checkFeatureFlag(flag);
                });
            }

            if (child?.conditionalVisibility) {
                if (
                    child.conditionalVisibility.includes("isCarrier")
                    && !getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER)
                ) {
                    return false;
                }

                if (
                    child.conditionalVisibility.includes("isBroker")
                    && !getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER)
                ) {
                    return false;
                }
            }

            hasPerm = !!(child?.perm ?? []).find(perm => {
                return Resources[perm]
                    ? checkPerm(Resources[perm], READ_PERM)
                    : false
            });
        }

        return hasPerm && isAuthorized; // is visible
    }

    return (
        mainMenu.filter(item => checkIfMenuIsVisible(item)).map((item) => {
                const Icon = iconList[item.icon];
                const isCurrent = (item?.currentList ?? []).includes(currentPath);
                return !item.children
                    ? (
                        <div key={item.name}>
                            <button
                                onClick={() => history.push(item.link)}
                                onMouseDown={(e) => {
                                    if (e.button === 1) {
                                        window.open(`${window.location.origin}${item.link}`, '_blank');
                                    }
                                }}
                                className={classNames(
                                    isCurrent ? 'bg-tm-gray-200 text-tm-gray-900' : 'text-tm-gray-700',
                                    'hover:bg-tm-gray-50 hover:text-tm-gray-900 group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-btn focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-link'
                                )}
                            >
                                {Icon && (
                                    <Icon
                                        className={classNames(
                                            'mr-3 flex-shrink-0 h-6 w-6',
                                            isCurrent ? 'text-tm-gray-700' : 'text-tm-gray-400'
                                        )}
                                        aria-hidden="true"
                                    />
                                )}
                                {item.name}
                            </button>
                        </div>
                    )
                    : <MainMenuItem key={item.name} item={item} currentPath={currentPath} iconList={iconList}
                                    checkIfChildIsVisible={checkIfChildIsVisible}/>
            }
        )
    )
}


const MainMenuItem = ({item, iconList, currentPath, checkIfChildIsVisible}) => {
    const isCurrent = (item?.currentList ?? []).includes(currentPath);

    return (
        <Disclosure as="div" key={item.name} className="space-y-1" defaultOpen={isCurrent}>
            {({open}) => {
                const Icon = iconList[item.icon];

                return (
                    <>
                        <Disclosure.Button
                            className={
                                classNames(
                                    open ? "text-tm-gray-900" : "text-tm-gray-700",
                                    'hover:bg-tm-gray-50 group w-full flex items-center pl-2 pr-1 py-1 text-left text-sm font-medium rounded-btn focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-link'
                                )}
                        >
                            {!!Icon && (
                                <Icon
                                    className={
                                        classNames(
                                            open ? "text-tm-gray-700" : "text-tm-gray-400",
                                            "mr-3 flex-shrink-0 h-6 w-6"
                                        )
                                    }
                                    aria-hidden="true"
                                />
                            )}
                            <span className="flex-1">{item.name}</span>
                            <svg
                                className={classNames(
                                    open ? 'rotate-90' : '',
                                    'text-tm-gray-400 ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150'
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                            </svg>
                        </Disclosure.Button>

                        <Disclosure.Panel className="space-y-1">
                            {item.children.filter(subItem => checkIfChildIsVisible(subItem)).map((subItem) => {
                                    const IconSubItem = iconList[subItem.icon];
                                    const isCurrent = (subItem?.currentList ?? []).includes(currentPath);

                                    return (
                                        <Link
                                            key={subItem.name}
                                            to={`${subItem.link}`}
                                            className={classNames(
                                                isCurrent ? 'font-bold bg-tm-gray-200 text-tm-gray-900' : 'text-tm-gray-700',
                                                'hover:bg-tm-gray-50 hover:text-tm-gray-900 pl-11 group w-full flex items-center pr-1 py-1.5 text-left text-sm font-medium rounded-btn focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-link'
                                            )}
                                        >
                                            {!!IconSubItem && (
                                                <IconSubItem className="h-4 w-4 mr-2 text-tm-gray-700"
                                                             aria-hidden="true"/>
                                            )}

                                            {subItem.name}
                                        </Link>
                                    )
                                }
                            )}
                        </Disclosure.Panel>

                        {item.htmlAfter}
                    </>
                )
            }}
        </Disclosure>
    )
}
