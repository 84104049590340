import React, {Component} from 'react'
import PublicLayout from '../../../common/components/layout-public'
import connect from 'react-redux/es/connect/connect'
import {Field, FieldsManager} from '../../../data/services/fields'
import {resetPassword, resetUserMessage} from '../../../data/actions/user'
import AuthLayout from "../auth-layout";
import {Link} from "react-router-dom";
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import FieldText from "../../../common/components/fields/field-text";
import {LoaderSmall} from "../../../common/components/loader";
import InfoBar from "../../../common/components/info-paragraph/info-bar";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                username: new Field('username', '', ['email', 'empty'])
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage())
        document.body.classList.add('background', 'no-footer')
    }

    componentWillUnmount() {
        document.body.classList.remove('background', 'no-footer')
    }

    handleInputChange = (name, value) => {
        const fieldsClone = this.state.fields;
        fieldsClone[name].errorMessage = "";
        this.setState({
            fields: FieldsManager.updateField(fieldsClone, name, value)
        }, () => {
            if (this?.props?.user?.reset) {
                this.props.dispatch(resetUserMessage());
            }
        })
    }

    submit = (event) => {
        event && event.preventDefault()

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.username.errorMessage) {
                this.props.dispatch(resetPassword({
                    username: this.state.fields.username.value
                }))
            }
        })
    }

    render() {
        const {translate, user} = this.props
        const isLoading = user?.isLoading;
        return (
            <PublicLayout {...this.props}>
                <AuthLayout>
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="h-14 w-auto"
                                src="/images/logo.png"
                                alt="Your Company"
                            />

                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight">
                                {translate('text.reset_password')}
                            </h2>

                            <p className="max-w-xs text-tm-gray-700">Enter your email and we&rsquo;ll send you
                                instructions to reset your password</p>
                        </div>

                        <form className="mt-10 space-y-6" onSubmit={this.submit}>
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-medium leading-6 mb-2">
                                    Email address
                                </label>

                                <FieldText
                                    className="form-control"
                                    onFocus={false}
                                    onChange={this.handleInputChange}
                                    placeholder={''}
                                    type={'email'}
                                    addClass={'form-control'}
                                    {...this.state.fields.username}
                                />
                            </div>

                            <div className="flex justify-content-end items-center">
                                <button
                                    disabled={isLoading}
                                    className="btn btn-primary justify-center w-full disabled:bg-transparent disabled:border-primary disabled:text-primary"
                                    onClick={this.submit}
                                >
                                        <span className="px-7 relative">
                                            {isLoading && (
                                                <LoaderSmall addClass="absolute left-0"/>
                                            )}

                                            {!user.reset && (
                                                translate('btn.reset_password')
                                            )}

                                            {user.reset && (
                                                translate('btn.reset_password_again')
                                            )}
                                        </span>
                                </button>
                            </div>
                        </form>

                        <div className="min-h-[2.5rem] mt-4">
                            {user.error && (
                                <InfoBar
                                    type="danger"
                                    addClass="justify-center"
                                >
                                    {translate(this.props.user.errorMessage)}
                                </InfoBar>
                            )}

                            {user.reset && (
                                <InfoBar
                                    type="success"
                                    Icon={CheckCircleIcon}
                                    addClass="justify-center">{translate('text.reset_password_sent')}</InfoBar>
                            )}
                        </div>

                        <div className="flex justify-content-end items-center">
                            <Link className="btn btn-text justify-center w-full font-semibold"
                                  to={"/login"}
                            >
                                <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                {translate('btn.back_to_login')}
                            </Link>
                        </div>
                    </div>
                </AuthLayout>
            </PublicLayout>
        )
    }
}

export default connect(state => state)(ResetPasswordView)
