import React from "react";

export default function Subtitle(props) {
    const {subtitle, strapline, className, children} = props;

    return (
        <div className={className}>
            <p className="text-lg leading-6 font-medium text-tm-gray-900">{subtitle}</p>
            {!!strapline && (
                <p className="text-tm-gray-700 text-sm">{strapline}</p>
            )}

            {children}
        </div>
    )
}