import React, {useEffect, useRef, useState} from 'react'
import Layout from '../../../common/components/layout-public'
import {Field, FieldsManager} from '../../../data/services/fields'
import {login, resetUserMessage} from '../../../data/actions/user'
import {connect, useSelector} from "react-redux";
import {getProp, openInNewTab} from "../../../common/util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import FieldText from "../../../common/components/fields/field-text";
import FieldPassword from "../../../common/components/fields/field-password";
import {LoaderSmall} from "../../../common/components/loader";
import {Link} from "react-router-dom";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import AuthLayout from "../auth-layout";
import {getUser, saveJWTToken} from "../../../common/util/util-auth";

const LoginView = (props) => {
    /** Constants
     ================================================================= */
    const {history, translate, user, location, loginUserData, resetUserMessageData} = props;
    const fieldEmailRef = useRef();

    const isLoading = !!user?.isLoading;

    const ui = useSelector(state => state.ui);
    const systemName = ui?.layout?.apps?.find(it => it.isCurrent)?.name ?? "";
    const systemDescription = ui?.layout?.apps?.find(it => it.isCurrent)?.description ?? "";
    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => ({
        username: new Field('username', '', ['empty', 'email'], false, 'email'),
        password: new Field('password', '', ['empty'], false, 'password')
    })

    const [fields, setFields] = useState(getFields())
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        resetUserMessageData();
        if (fieldEmailRef.current) {
            fieldEmailRef.current.focus();
        }
    }, [])

    useEffect(() => {
        if (getProp(user, 'data.access_token', false) && !!getUser()) {
            saveJWTToken(getProp(user, 'data', ""));

            let pathName = "/home";

            if (LocalStorage.get("redirect_path")) {
                pathName = LocalStorage.get("redirect_path");
                LocalStorage.remove("redirect_path");
            }

            history.replace(pathName);
        }
    }, [user]);

    /** Data events
     ================================================================= */
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    };

    const onSubmit = (event) => {
        event && event.preventDefault();

        const validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            loginUserData(FieldsManager.getFieldKeyValues(validatedFields))
        } else {
            setFields(validatedFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        fields[name].errorMessage = "";
        setFields(FieldsManager.updateField(fields, name, value))
    }

    const handleTogglePasswordVisibility = () => {
        let fieldsTmp = Object.assign({}, fields)
        fieldsTmp.password.type = isPasswordVisible ? 'password' : 'text'
        setIsPasswordVisible(!isPasswordVisible)
        setFields(fieldsTmp)
    }

    /** Render
     ================================================================= */
    return (
        <Layout {...props}>
            <AuthLayout>
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    {location.search === '?redirected' && (
                        <div className="mb-16">
                            <InfoBar
                                message={translate('text.session_expired')}
                            />
                        </div>
                    )}

                    <div>
                        <img
                            className="h-14 w-auto"
                            src="/images/logo.png"
                            alt="Your Company"
                        />
                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight">
                            Sign in to your account
                        </h2>

                        {(!!systemName || !!systemDescription) && (
                            <div className="mt-4">
                                {!!systemName && (
                                    <div className="mr-1 inline mt-8 text-base font-bold leading-4 tracking-tight">
                                        {systemName}.
                                    </div>
                                )}

                                {!!systemDescription && (
                                    <div className="inline mt-2 text-base leading-4 text-tm-gray-500">
                                        {systemDescription}
                                    </div>
                                )}
                            </div>
                        )}

                        <p className="mt-2 text-sm leading-6 text-tm-gray-500">
                            Not a member?{' '}
                            <a
                                href="https://accur8.space/pricing/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary hover:text-primary-shade font-semibold"
                            >
                                Start a free trial
                            </a>
                        </p>
                    </div>

                    <div className="mt-10">
                        <div>
                            <form className="space-y-6" onKeyDown={onKeyDown}>
                                <div>
                                    <label htmlFor="email"
                                           className="block text-sm font-medium leading-6">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <FieldText
                                            {...fields.username}
                                            innerRef={fieldEmailRef}
                                            onChange={handleInputChange}
                                            onFocus={false}
                                            placeholder={''} type={'email'}
                                            disabled={isLoading}
                                            addClass='form-control'
                                            translate={translate}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password"
                                           className="block text-sm font-medium leading-6">
                                        Password
                                    </label>

                                    <div className="mt-2 relative">
                                        {!isPasswordVisible && (
                                            <FieldPassword
                                                onFocus={false}
                                                onChange={handleInputChange} {...fields.password}
                                                placeholder={''}
                                                disabled={isLoading}
                                                addClass='form-control'
                                                translate={translate}
                                            />
                                        )}

                                        {!!isPasswordVisible && (
                                            <FieldText
                                                className="form-control"
                                                onFocus={false}
                                                disabled={isLoading}
                                                onChange={handleInputChange} {...fields.password}
                                                placeholder={''} addClass={'form-control'}/>
                                        )}

                                        <button
                                            onClick={handleTogglePasswordVisibility}
                                            type="button"
                                            className="absolute opacity-75 cursor-pointer text-tm-gray-600 right-4 top-2"
                                        >
                                            {!isPasswordVisible ? <EyeIcon className="w-5 h-5"/> :
                                                <EyeSlashIcon className="w-5 h-5"/>}
                                        </button>
                                    </div>
                                </div>

                                <div className="flex items-center justify-end">
                                    <div className="text-sm leading-6">
                                        <Link
                                            className="text-primary hover:text-primary-shade font-semibold"
                                            to="/reset-password">{translate('text.forgot_password')}</Link>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        onClick={onSubmit}
                                        className="btn btn-primary w-full justify-center disabled:bg-transparent disabled:border-primary"
                                        disabled={isLoading}
                                    >
                                         <span className="px-7 relative">
                                            {isLoading && (
                                                <LoaderSmall addClass="absolute left-0"/>
                                            )}

                                             {translate('text.sign_in')}
                                         </span>
                                    </button>
                                </div>

                                {user?.error && (
                                    <InfoBar addClass="justify-center text-sm items-center" type="danger"
                                             message={translate(user.errorMessage)}/>
                                )}

                                <div className={"flex justify-center text-base"}>
                                    {translate("text.by_using_our_platform")}
                                </div>

                                <div className={"flex justify-between text-base"}>
                                    <button type="button" className={"text-primary ml-4"} onClick={() => openInNewTab('https://accur8.services/terms-of-use/')}>{translate("text.termsOfUse")}</button>
                                    <button type="button" className={"text-primary mr-2"} onClick={() => openInNewTab('https://accur8.services/privacy-policy/')}>{translate("text.privacy_policy")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUserData: (data) => {
            dispatch(login(data))
        },
        resetUserMessageData: () => {
            dispatch(resetUserMessage())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
