import axios from "axios";
import Env from "../../util/env";
import {processError, processRegisterResponse, processResponse} from "./api-util";
import FileSaver from "file-saver";

export default class Api {

    static getResource(user, query, resource) {
        return axios.get(
            Env.getApiUrl('api/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static postResource(user, query, resource) {
        return axios.post(
            Env.getApiUrl('api/' + resource),
            query,
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static createResource(user, params, resource) {
        return axios.post(
            Env.getApiUrl('api/' + resource),
            params,
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static createResourceDataForm(user, params, resource, file, fileKey) {
        const formData = new FormData();

        if (fileKey && file) {
            formData.append(fileKey, file);
        }

        if (params) {
            Object.keys(params).forEach((it) => {
                formData.append(it, params[it]);
            });
        }

        return axios.post(
            Env.getApiUrl('api/' + resource),
            formData,
            {
                headers: user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }


    static putResource(user, params, resource) {
        return axios.put(
            Env.getApiUrl('api/' + resource),
            params,
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static updateResource(user, params, resource) {
        return axios.patch(
            Env.getApiUrl('api/' + resource),
            params,
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static deleteResource(user, query, resource) {
        return axios.delete(
            Env.getApiUrl('api/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static login(username, password) {
        return axios.post(
            Env.getApiUrl('token'),
            ('grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password)),
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static refresh(token) {
        return axios.post(
            Env.getApiUrl('refresh'),
            'grant_type=refresh_token&refresh_token=' + token,
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static register(params, file) {
        const formData = new FormData();
        formData.append('CompanyImage', file);
        if (params) {
            Object.keys(params).forEach((it) => {
                formData.append(it, params[it]);
            });
        }
        return axios.post(
            Env.getApiUrl('api_pub/register'),
            formData,
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return processRegisterResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static userInfo(user) {
        return axios.get(
            Env.getApiUrl('api/user'),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static resetPassword(username) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password'),
            {
                username: username
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static resetPasswordConfirm(username, token, password) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password-confirm'),
            {
                username: username,
                token: token,
                password: password
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static sendSupport(user, params) {

        return axios.post(
            Env.getApiUrl('api/support'),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            },
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getLookups(user, query) {
        return axios.get(
            Env.getApiUrl('api/lookup', query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static getUserCommands(user, query) {
        return axios.get(
            Env.getApiUrl('api/user/commands', query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static downloadFile(user, query, resource) {
        return axios({
            url: Env.getApiUrl('api/' + resource, query),
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + user.access_token
            }
        }).then((response) => {
            FileSaver.saveAs(new Blob([response.data]), query.name);
            return {
                'status': 0,
                'data': true
            };
        }).catch((error) => {
            return {
                'status': 1,
                'data': false
            };
        });
    }

    static uploadFile(user, resource, file, params = {}) {
        const formData = new FormData();
        formData.append('Document', file);
        Object.keys(params).forEach((it) => {
            formData.append(it, params[it]);
        })

        return axios.post(
            Env.getApiUrl('api/' + resource),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static uploadFiles(user, resource, files, params = [], descriptions = {}, customID = null) {
        const requests = files.map((file, i) => {
            const formData = new FormData();
            formData.append('Document', file);
            if (params && params[i]) {
                Object.keys(params[i]).forEach((it) => {
                    formData.append(it, params[i][it]);
                });
            }
            if (params.id) {
                formData.append('id', params.id);
            }

            if (params[customID] && customID) {
                formData.append(customID, params[customID]);
            }

            if (descriptions && descriptions[i]) {
                formData.append("Description", descriptions[i]);
            }

            return axios.post(
                Env.getApiUrl('api/' + resource),
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            )
        });

        return axios.all(requests)
            .then(axios.spread((response) => {
                return processResponse(response);
            }))
            .catch((error) => {
                return processError(error);
            })
    }

    static uploadDocument(user, description, file, type, resource, id, customID = null) {
        const formData = new FormData();
        formData.append('Document', file);

        if (file.DocumentTypeID) {
            formData.append('DocumentTypeID', file.DocumentTypeID);
        }
        if (file.ExpiryDate) {
            formData.append('ExpiryDate', file.ExpiryDate);
        }

        if (file.Description) {
            formData.append('Description', file.Description);
        }

        if (file.params) {
            Object.keys(file.params).forEach(key => {
                formData.append(key, file.params[key]);
            })
        }
        formData.append('id', id);

        if (customID) {
            formData.append(customID, id);
        }

        return axios.post(
            Env.getApiUrl('api/' + (resource ? resource : "documents")),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static uploadDocuments(user, id, files, type, descriptions, resource, params, customID = null) {
        const requests = files.map((file, i) => {
            const formData = new FormData();
            formData.append('Document', file);
            if (type) {
                formData.append('DocumentTypeID', type);
            }
            if (file.DocumentTypeID) {
                formData.append('DocumentTypeID', file.DocumentTypeID);
            }
            if (file.ExpiryDate) {
                formData.append('ExpiryDate', file.ExpiryDate);
            }
            if (id) {
                formData.append('id', id);
            }

            if (customID) {
                formData.append(customID, id);
            }

            if (file.Description) {
                formData.append('Description', file.Description);
            }
            if (descriptions && descriptions[i]) {
                formData.append("Description", descriptions[i]);
            }
            if (file.description) {
                formData.append("Description", file.description);
            }
            if (file.params) {
                Object.keys(file.params).forEach(key => {
                    formData.append(key, file.params[key]);
                })
            }
            if (params) {
                Object.keys(params).forEach((it) => {
                    formData.append(it, params[it]);
                });
            }
            return axios.post(
                Env.getApiUrl('api/' + resource),
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            )
        });

        return axios.all(requests)
            .then(axios.spread((response) => {
                return processResponse(response);
            }))
            .catch((error) => {
                return processError(error);
            })
    }

    static getNotificationList(user) {
        return axios.get(
            Env.getNotificationServiceUrl("/api/get-notifications"),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getCommunicationList(user, query) {
        return axios.get(
            Env.getNotificationServiceUrl("/api/messages", query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getConversation(user, id) {
        return axios.get(
            Env.getNotificationServiceUrl("/api/messages/" + id),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static updatePassword(user, params) {
        return axios.patch(
            Env.getApiUrl('api/user/change-password'),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static uploadOcrFillDocuments(user, file, templateID) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('TemplateID', templateID);

        const request = axios.post(
            Env.getOcrApiUrl('ocr/scan"', {}),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )

        return axios.all([request])
            .then(axios.spread((response) => {
                return processResponse(response);
            }))
            .catch((error) => {
                return processError(error);
            })
    }

    /** Websocket resource calls
     ========================================================= */
    static getWebsocketResource(user, query, resource) {

        return axios.get(
            Env.getChatServiceUrl('api/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }
}
